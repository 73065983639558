import {unformat} from 'helpers/number';

export const mpGetInstallments = (paymentMethodId, amount) =>
  new Promise((resolve, reject) => {
    window.Mercadopago.getInstallments(
      {
        payment_method_id: paymentMethodId,
        amount: unformat(amount),
        issuer_id: undefined,
      },
      (status, response) => {
        if (status === 200 || status === 201) {
          const options = response[0].payer_costs.map(payerCost => ({
            label: payerCost.recommended_message,
            value: payerCost.installments,
          }));
          resolve(options);
        }
        reject(JSON.stringify(response, null, 4));
      },
    );
  });
