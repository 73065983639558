import React, {useEffect} from 'react';

import {ThemeProvider} from 'styled-components';
import {defaultTheme} from 'theme';

import AppNavigation from 'routes';

import {loadGtag} from './helpers/analytics';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'animate.css';

function App() {
  useEffect(() => {
    if (process.env.NODE_ENV !== 'development') {
      loadGtag('G-V0XL7GXEWR');
    }
  }, []);

  return (
    <ThemeProvider theme={defaultTheme}>
      <AppNavigation />
    </ThemeProvider>
  );
}

export default App;
