import Immutable from 'seamless-immutable';
import update from 'immutability-helper';

export const Types = {
  PEDIDO_ADD: 'pedido/PEDIDO_ADD',
  PEDIDO_REMOVE: 'pedido/PEDIDO_REMOVE',
  PEDIDO_RESET: 'pedido/PEDIDO_RESET',
  PEDIDO_UPDATE: 'pedido/PEDIDO_UPDATE',
  PEDIDO_SEND_REQUEST: 'pedido/PEDIDO_SEND_REQUEST',
  PEDIDO_SEND_SUCCESS: 'pedido/PEDIDO_SEND_SUCCESS',
  PEDIDO_SEND_ERROR: 'pedido/PEDIDO_SEND_ERROR',
};

const initialState = Immutable({
  sending: false,
  itens: [],
});

export const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case Types.PEDIDO_ADD: {
      const {item} = action.payload;
      return {
        ...state,
        itens: update(state.itens, {
          $push: [{...item}],
        }),
      };
    }
    case Types.PEDIDO_REMOVE: {
      const {uuid} = action.payload;
      return {
        ...state,
        itens: state.itens.filter(p => p.uuid !== uuid),
      };
    }
    case Types.PEDIDO_UPDATE: {
      const {item} = action.payload;
      return {
        ...state,
        itens: state.itens.map(i => {
          if (i.uuid !== item.uuid) {
            return i;
          }
          return {
            ...i,
            ...item,
          };
        }),
      };
    }
    case Types.PEDIDO_RESET: {
      return {
        ...state,
        ...initialState,
      };
    }
    case Types.PEDIDO_SEND_REQUEST: {
      return {
        ...state,
        sending: true,
      };
    }
    case Types.PEDIDO_SEND_SUCCESS: {
      return {
        ...state,
        sending: false,
        itens: [],
      };
    }
    case Types.PEDIDO_SEND_ERROR: {
      return {
        ...state,
        sending: false,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export const Creators = {
  pedidoAdd: item => ({
    type: Types.PEDIDO_ADD,
    payload: {
      item,
    },
  }),
  pedidoRemove: uuid => ({
    type: Types.PEDIDO_REMOVE,
    payload: {
      uuid,
    },
  }),
  pedidoReset: () => ({
    type: Types.PEDIDO_RESET,
    payload: {},
  }),
  pedidoUpdate: item => ({
    type: Types.PEDIDO_UPDATE,
    payload: {item},
  }),
  pedidoSendRequest: () => ({
    type: Types.PEDIDO_SEND_REQUEST,
    payload: {},
  }),
  pedidoSendSuccess: () => ({
    type: Types.PEDIDO_SEND_SUCCESS,
    payload: {},
  }),
  pedidoSendError: () => ({
    type: Types.PEDIDO_SEND_ERROR,
    payload: {},
  }),
};
