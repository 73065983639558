import React, {useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {Badge} from 'reactstrap';
import {parseISO, addMinutes, differenceInSeconds, format} from 'date-fns';

import {useTranslation} from 'react-i18next';

import {
  Card,
  CardContent,
  ItemHeader,
  ItemSubGroup,
  ItemNameContainer,
  ItemStatusContainer,
  ItemText,
  ItemSubText,
  ItemTextHeader,
} from './styles';

import {secondsToHMS, getStatusColor} from './utils';

const OrderItem = ({item}) => {
  const {t} = useTranslation();
  const amount = useMemo(
    () => parseFloat(item.quantidade, 10).toFixed(3),
    [item],
  );
  const [secondsPassed, setSecondsPassed] = useState(0);

  const orderDateTime = useMemo(
    () => format(parseISO(item.horapedido), 'dd/MM/yyyy HH:mm:ss'),
    [item.horapedido],
  );

  useEffect(() => {
    const interval = setInterval(() => {
      const orderDate = parseISO(item.horapedido);
      // console.log('ORDER DATE:', orderDate);

      const estimatedDate = addMinutes(
        orderDate,
        parseInt(item.tempoprevisto, 10),
      );

      // const differenceInMs = differenceInMilliseconds(
      //   new Date(),
      //   estimatedDate,
      // );

      // Convert milliseconds to hours, minutes, and seconds
      // const hours = Math.floor(differenceInMs / (1000 * 60 * 60));
      // const minutes = Math.floor(
      //   (differenceInMs % (1000 * 60 * 60)) / (1000 * 60),
      // );
      // const seconds = Math.floor((differenceInMs % (1000 * 60)) / 1000);

      // console.log(
      //   `Difference: ${hours} hours, ${minutes} minutes, ${seconds} seconds`,
      // );

      const diffSeconds = differenceInSeconds(estimatedDate, new Date());

      setSecondsPassed(diffSeconds);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [item]);

  const renderTime = () => {
    if (secondsPassed < 0) {
      return (
        <>
          <ItemSubText>
            {t('order.orderEstimatedTime')} {`${item.tempoprevisto}`}{' '}
            {t('order.minutes')}
          </ItemSubText>
          <ItemSubText>
            {t('order.timeLeft')} 00:00:00
            <Badge color="danger">{t('order.late')}</Badge>
          </ItemSubText>
        </>
      );
    }

    const {hours, minutes, seconds} = secondsToHMS(secondsPassed);

    return (
      <>
        <ItemSubText>
          {t('order.orderEstimatedTime')} {`${item.tempoprevisto}`}{' '}
          {t('order.minutes')}
        </ItemSubText>
        <ItemSubText>
          {t('order.timeLeft')} {`${hours}:${minutes}:${seconds}`}
        </ItemSubText>
      </>
    );
  };

  const renderSubText = () => {
    const status = String(item.posicaoatual)
      .toUpperCase()
      .replace(/[^a-zA-Z]+/g, '');

    if (status === 'AGUARDANDO') {
      return (
        <ItemSubGroup>
          <ItemSubText>
            {t('order.orderDate')} {orderDateTime}
          </ItemSubText>
          {renderTime()}
        </ItemSubGroup>
      );
    }
    if (status === 'INICIADO') {
      return (
        <ItemSubGroup>
          <ItemSubText>
            {t('order.orderDate')} {orderDateTime}
          </ItemSubText>
          {renderTime()}
        </ItemSubGroup>
      );
    }
    if (status === 'FINALIZADO') {
      return (
        <ItemSubGroup>
          <ItemSubText>
            {t('order.orderDate')} {orderDateTime}
          </ItemSubText>
          <ItemSubText />
        </ItemSubGroup>
      );
    }
    if (status === 'ENTREGUE') {
      return (
        <ItemSubGroup>
          <ItemSubText>
            {t('order.orderDeliveryDate')} {item.dtentrega}
          </ItemSubText>
          <ItemSubText />
        </ItemSubGroup>
      );
    }
    return <ItemSubText />;
  };

  return (
    <Card>
      <ItemNameContainer>
        <ItemText>
          {`#${item.comanda}`} - {amount} - {item.produto}
        </ItemText>
      </ItemNameContainer>
      <CardContent>
        <ItemNameContainer>{renderSubText()}</ItemNameContainer>
        <ItemStatusContainer>
          <Badge color={getStatusColor(item.posicaoatual)}>
            {item.posicaoatual}
          </Badge>
        </ItemStatusContainer>
      </CardContent>
    </Card>
  );
};

OrderItem.propTypes = {
  item: PropTypes.shape({
    comanda: PropTypes.string,
    produto: PropTypes.string,
    quantidade: PropTypes.string,
    posicaoatual: PropTypes.string,
    horapedido: PropTypes.string,
    dthorainicio: PropTypes.string,
    dthorafim: PropTypes.string,
    dtentrega: PropTypes.string,
    tempoprevisto: PropTypes.string,
  }).isRequired,
};

export const OrdersTable = ({data = []}) => {
  const {t} = useTranslation();
  return (
    <>
      <ItemHeader>
        <ItemNameContainer>
          <ItemTextHeader>{t('order.product')}</ItemTextHeader>
        </ItemNameContainer>

        <ItemStatusContainer>
          <ItemTextHeader>{t('order.status')}</ItemTextHeader>
        </ItemStatusContainer>
      </ItemHeader>
      {data.map((item, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <OrderItem item={item} key={index} />
      ))}
    </>
  );
};

OrdersTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
};
