import React, {useState, forwardRef, useMemo} from 'react';
import PropTypes from 'prop-types';
import {Row, Col, FormGroup, Input, FormFeedback, Label} from 'reactstrap';
import {Formik, Field, Form} from 'formik';

import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

import {IButton} from 'components/button';

import notification from 'providers/notification';

import {Flags} from './Flags';
import {Card} from './Card';
import {
  formatCreditCardNumber,
  formatCVC,
  formatExpirationDate,
} from './util/formatter';
import {InputNumber} from './InputNumber';
import {IdentificationTypes} from './IdentificationTypes';
import {
  MercadoPagoLoader,
  mpCreateToken,
  mpGetInstallments,
} from './mercadoPago';

import {validatePaymentData} from './util/validate';
import {getCardTypeByValue} from './util/cardTypes';
import {getInstallmentOptions} from './util/installments';

import {VALUE_TOTAL_EQUAL} from '../../utils';

import {
  Container,
  CardContainer,
  PaymentFooterContent,
  PaymentFormContent,
} from './styles';

export const PaymentForm = forwardRef(
  (
    {
      handleSubmitPayment,
      total,
      canChangeTotal,
      handleChangeTotal,
      handlePaymentValueBlur,
    },
    ref,
  ) => {
    const [editing, setEditing] = useState(false);
    const {t} = useTranslation();
    const paymentConfig = useSelector(state => state.config?.config?.pagamento);

    const acceptCards = paymentConfig.bandeiras;
    const {moeda, parcelas, valorminimo} = paymentConfig;

    const isMercadoPago = useMemo(() => {
      if (
        paymentConfig.mercadopago &&
        paymentConfig.mercadopago.active &&
        paymentConfig.mercadopago.publickey
      ) {
        return true;
      }
      return false;
    }, [paymentConfig]);

    const [installmentOptions, setInstallmentOptions] = useState(
      isMercadoPago ? [] : getInstallmentOptions(total, valorminimo, parcelas),
    );

    return (
      <Container>
        <MercadoPagoLoader isMercadoPago={isMercadoPago} onLoad={() => {}}>
          <Formik
            innerRef={ref}
            initialValues={{
              email: '',
              name: '',
              cardNumber: '',
              expiryDate: '',
              cvc: '',
              active: '',
              brand: '',
              installments: '',
              docType: '',
              docNumber: '',
            }}
            onSubmit={values => {
              if (!editing) {
                if (isMercadoPago) {
                  mpCreateToken(values)
                    .then(mpToken => {
                      handleSubmitPayment({...values, token: mpToken});
                    })
                    .catch(() => {
                      notification.warning(t('payment.invalidParams'));
                    });
                } else {
                  handleSubmitPayment(values);
                }
              }
            }}
            validate={async values => {
              const valid = await validatePaymentData(values, isMercadoPago);
              return valid;
            }}>
            {({
              handleSubmit,
              values,
              handleBlur,
              handleChange,
              setFieldValue,
              setValues,
              errors,
              touched,
            }) => (
              <Form onSubmit={handleSubmit} noValidate>
                <PaymentFormContent>
                  <CardContainer>
                    <Card
                      number={values.cardNumber || ''}
                      name={values.name || ''}
                      expiry={values.expiryDate || ''}
                      cvc={values.cvc || ''}
                      focused={values.active}
                      acceptCards={acceptCards || []}
                    />
                  </CardContainer>

                  <Row>
                    <Col
                      xs={{size: 12}}
                      sm={{size: 6, offset: 0}}
                      md={{size: 8, offset: 0}}
                      className="d-flex align-items-center">
                      <FormGroup
                        check
                        className="d-flex flex-direction-row justify-content-start align-items-center mb-2">
                        <Input
                          name="paymentMethod"
                          type="radio"
                          disabled={false}
                          checked
                          onChange={() => {}}
                          bsSize="xs"
                        />{' '}
                        <Label check>{t('payment.creditCard')}</Label>
                      </FormGroup>
                    </Col>
                    <Col
                      xs={{size: 12}}
                      sm={{size: 6, offset: 0}}
                      md={{size: 4, offset: 0}}>
                      <FormGroup>
                        <InputNumber
                          moeda={moeda}
                          value={total}
                          handleChangeValue={value => {
                            if (!isMercadoPago) {
                              handleChangeTotal(value);
                            }
                          }}
                          handleFocus={() => {
                            setEditing(true);
                          }}
                          handleBlur={() => {
                            setEditing(false);
                            handlePaymentValueBlur();
                          }}
                          disabled={
                            canChangeTotal === VALUE_TOTAL_EQUAL ||
                            isMercadoPago
                          }
                        />
                      </FormGroup>
                    </Col>

                    <Col xs={12}>
                      <FormGroup>
                        <Field name="email">
                          {({field}) => {
                            const invalid = errors.email && touched.email;
                            return (
                              <Input
                                invalid={invalid}
                                className=""
                                autoComplete="email"
                                type="email"
                                placeholder={t('payment.email')}
                                name={field.name}
                                value={values.email || ''}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                onFocus={() => {
                                  setFieldValue('active', 'email');
                                }}
                              />
                            );
                          }}
                        </Field>
                        {errors.email && touched.email ? (
                          <FormFeedback valid={false}>
                            {errors.email}
                          </FormFeedback>
                        ) : null}
                      </FormGroup>
                    </Col>

                    <Col xs={12}>
                      <FormGroup>
                        <Field name="cardNumber">
                          {({field}) => {
                            const invalid =
                              errors.cardNumber && touched.cardNumber;
                            return (
                              <Input
                                invalid={invalid}
                                className="cc-number"
                                pattern="[\d| ]{16,22}"
                                autoComplete="cc-number"
                                type="text"
                                placeholder={t('payment.cardNumber')}
                                name={field.name}
                                value={values.cardNumber || ''}
                                onChange={e => {
                                  const {value} = e.target;
                                  const findBrand = getCardTypeByValue(value);
                                  let brand = null;
                                  if (findBrand) {
                                    brand = findBrand.type;
                                  }

                                  if (brand && isMercadoPago) {
                                    mpGetInstallments(brand, total).then(
                                      mpInstallmentOptions => {
                                        setInstallmentOptions(
                                          mpInstallmentOptions,
                                        );
                                      },
                                    );
                                  }

                                  setValues(prevValues => ({
                                    ...prevValues,
                                    cardNumber: formatCreditCardNumber(value),
                                    brand,
                                    installments: '',
                                  }));
                                }}
                                onBlur={handleBlur}
                                onFocus={() => {
                                  setFieldValue('active', 'cardNumber');
                                }}
                              />
                            );
                          }}
                        </Field>
                        {errors.cardNumber && touched.cardNumber ? (
                          <FormFeedback valid={false}>
                            {errors.cardNumber}
                          </FormFeedback>
                        ) : null}
                      </FormGroup>
                    </Col>

                    <Col xs={12}>
                      <FormGroup>
                        <Field name="name">
                          {({field}) => {
                            const invalid = errors.name && touched.name;
                            return (
                              <Input
                                invalid={invalid}
                                className="cc-name"
                                autoComplete="cc-name"
                                type="text"
                                placeholder={t('payment.name')}
                                name={field.name}
                                value={values.name || ''}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                onFocus={() => {
                                  setFieldValue('active', 'name');
                                }}
                              />
                            );
                          }}
                        </Field>
                        {errors.name && touched.name ? (
                          <FormFeedback valid={false}>
                            {errors.name}
                          </FormFeedback>
                        ) : null}
                      </FormGroup>
                    </Col>

                    {isMercadoPago ? <IdentificationTypes /> : null}

                    <Col xs={6} sm={6} md={4}>
                      <FormGroup>
                        <Field name="expiryDate">
                          {({field}) => {
                            const invalid =
                              errors.expiryDate && touched.expiryDate;
                            return (
                              <Input
                                invalid={invalid}
                                className="cc-expiry"
                                pattern="\d\d/\d\d"
                                autoComplete="cc-exp"
                                type="tel"
                                placeholder={t('payment.expiryDate')}
                                name={field.name}
                                value={values.expiryDate || ''}
                                onChange={e => {
                                  setFieldValue(
                                    'expiryDate',
                                    formatExpirationDate(e.target.value),
                                  );
                                }}
                                onBlur={handleBlur}
                                onFocus={() => {
                                  setFieldValue('active', 'expiryDate');
                                }}
                              />
                            );
                          }}
                        </Field>
                        {errors.expiryDate && touched.expiryDate ? (
                          <FormFeedback valid={false}>
                            {errors.expiryDate}
                          </FormFeedback>
                        ) : null}
                      </FormGroup>
                    </Col>

                    <Col xs={6} sm={6} md={4}>
                      <FormGroup>
                        <Field name="cvc">
                          {({field}) => {
                            const invalid = errors.cvc && touched.cvc;
                            return (
                              <Input
                                invalid={invalid}
                                className="cc-cvc"
                                pattern="\d{3,4}"
                                autoComplete="cc-csc"
                                type="tel"
                                placeholder={t('payment.cvc')}
                                name={field.name}
                                value={values.cvc || ''}
                                onChange={e => {
                                  setFieldValue(
                                    'cvc',
                                    formatCVC(
                                      e.target.value,
                                      values.cardNumber,
                                    ),
                                  );
                                }}
                                onBlur={handleBlur}
                                onFocus={() => {
                                  setFieldValue('active', 'cvc');
                                }}
                              />
                            );
                          }}
                        </Field>
                        {errors.cvc && touched.cvc ? (
                          <FormFeedback valid={false}>
                            {errors.cvc}
                          </FormFeedback>
                        ) : null}
                      </FormGroup>
                    </Col>

                    <Col xs={12} sm={12} md={4}>
                      <FormGroup>
                        <Field name="installments">
                          {({field}) => {
                            const invalid =
                              errors.installments && touched.installments;
                            return (
                              <Input
                                invalid={invalid}
                                className="cc-installments"
                                autoComplete="off"
                                type="select"
                                name={field.name}
                                value={values.installments || ''}
                                onChange={e => {
                                  setFieldValue('installments', e.target.value);
                                }}
                                onBlur={handleBlur}
                                onFocus={() => {
                                  setFieldValue('active', 'installments');
                                }}>
                                <option value="">
                                  {t('payment.installments')}
                                </option>
                                {installmentOptions.map(i => (
                                  <option value={i.value} key={i.value}>
                                    {i.label}
                                  </option>
                                ))}
                              </Input>
                            );
                          }}
                        </Field>
                        {errors.installments && touched.installments ? (
                          <FormFeedback valid={false}>
                            {errors.installments}
                          </FormFeedback>
                        ) : null}
                      </FormGroup>
                    </Col>
                  </Row>
                </PaymentFormContent>
                <PaymentFooterContent>
                  <IButton
                    type="submit"
                    title={t('payment.confirm')}
                    disabled={editing}
                  />
                </PaymentFooterContent>
              </Form>
            )}
          </Formik>
          <div>
            <Flags
              acceptCards={acceptCards || []}
              isMercadoPago={isMercadoPago}
            />
          </div>
        </MercadoPagoLoader>
      </Container>
    );
  },
);

PaymentForm.propTypes = {
  handleSubmitPayment: PropTypes.func.isRequired,
  total: PropTypes.string.isRequired,
  handleChangeTotal: PropTypes.func.isRequired,
  handlePaymentValueBlur: PropTypes.func.isRequired,
  canChangeTotal: PropTypes.string.isRequired,
};
