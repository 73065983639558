import styled from 'styled-components';

import {colors} from 'styles';

export const ContainerBackdrop = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${colors.white};
  z-index: 99999;
  opacity: 0.7;
`;

export const Container = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: transparent;
  z-index: 999999;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;
