import styled from 'styled-components';
import {shade} from 'polished';

import {colors} from 'styles';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: transparent;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
`;

export const ErrorImage = styled.img`
  max-height: 150px;
`;

export const Title = styled.p`
  font-size: 1rem;
  color: ${colors.textDark};
`;

export const Button = styled.button`
  display: flex;
  height: 60px;
  align-self: stretch;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 2px solid ${colors.background};
  transition: background 0.5s;
  color: ${colors.background};
  font-size: 1.1rem;
  :hover {
    background-color: ${shade(0.25, colors.background)};
    color: ${colors.white};
  }
`;
