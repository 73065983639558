import styled from 'styled-components';

import {colors} from 'styles';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: transparent;
  overflow-y: auto;
`;

export const ResultContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: ${colors.grayLight};
  padding: 20px;
`;

export const Result = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 20px;
`;

export const ResultTitle = styled.p`
  font-size: 1.3rem;
  margin-top: 10px;
  margin-bottom: 20px;
  color: ${colors.background};
`;

export const ResultSubtitle = styled.p`
  font-size: 1.2rem;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 20px;
  color: ${colors.textDark};
`;

export const ResultFooter = styled.div`
  display: flex;
`;
