import React from 'react';
import PropTypes from 'prop-types';

import {Loading} from 'components/loading';
import {ContainerBackdrop, Container} from './styles';

export const OverlayLoading = ({visible, title}) => {
  if (!visible) {
    return null;
  }
  return (
    <>
      <ContainerBackdrop />
      <Container>
        <Loading title={title} />
      </Container>
    </>
  );
};

OverlayLoading.propTypes = {
  visible: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
};
