import styled from 'styled-components';

import {colors} from 'styles';

export const AdditionalContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 50px;
  margin-top: 1rem;
  margin-bottom: 1rem;
  p {
    margin-bottom: 0px;
  }
  .button {
    border-width: 2px;
    border-style: solid;
    border-color: ${colors.blue};
    background-color: ${colors.blue};
    height: 20px;
    justify-content: center;
    align-items: center;
  }
  .buttonContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 60px;
  }
  .buttonText {
    text-align: center;
    color: ${colors.white};
  }
  .textContainer {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    align-items: flex-start;
  }
  .valueContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    background-color: ${colors.white};
  }
  .valueText {
    font-size: 0.8rem;
    font-weight: bold;
    color: ${colors.textDark};
  }
  .valuePrice {
    font-size: 0.8rem;
    font-weight: bold;
    font-weight: normal;
    color: ${colors.textDark};
  }
`;
