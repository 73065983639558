/* eslint-disable no-plusplus */
/* eslint-disable no-cond-assign */
/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
import i18n from 'locale/i18n';
import {getCardTypeByValue} from './cardTypes';

const MONTH_REGEX = /(0[1-9]|1[0-2])/;

const EMPTY_FIELD = i18n.t('error.EMPTY_FIELD');
const EMPTY_EMAIL = i18n.t('error.EMPTY_EMAIL');
const EMPTY_NAME = i18n.t('error.EMPTY_NAME');
const EMPTY_CARD_NUMBER = i18n.t('error.EMPTY_CARD_NUMBER');
const EMPTY_EXPIRY_DATE = i18n.t('error.EMPTY_EXPIRY_DATE');
const EMPTY_CVC = i18n.t('error.EMPTY_CVC');
const EMPTY_INSTALLMENTS = i18n.t('error.EMPTY_INSTALLMENTS');

const INVALID_EMAIL = i18n.t('error.INVALID_EMAIL');
const INVALID_CARD_NUMBER = i18n.t('error.INVALID_CARD_NUMBER');
const INVALID_EXPIRY_DATE = i18n.t('error.INVALID_EXPIRY_DATE');
const INVALID_CVC = i18n.t('error.INVALID_CVC');

const MONTH_OUT_OF_RANGE = i18n.t('error.MONTH_OUT_OF_RANGE');
const YEAR_OUT_OF_RANGE = i18n.t('error.YEAR_OUT_OF_RANGE');
const DATE_OUT_OF_RANGE = i18n.t('error.DATE_OUT_OF_RANGE');

const validateEmail = email => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const validateLuhn = num => {
  let digit;
  let j;
  let len;
  let odd;
  let sum;
  odd = true;
  sum = 0;
  const digits = `${num}`.split('').reverse();
  for (j = 0, len = digits.length; j < len; j++) {
    digit = digits[j];
    digit = parseInt(digit, 10);
    if ((odd = !odd)) {
      digit *= 2;
    }
    if (digit > 9) {
      digit -= 9;
    }
    sum += digit;
  }
  return sum % 10 === 0;
};

const getCardNumberErros = cardNumber => {
  if (!cardNumber) {
    return EMPTY_CARD_NUMBER;
  }
  const rawCardNumber = cardNumber.replace(/\s/g, '');
  const cardType = getCardTypeByValue(rawCardNumber);
  if (cardType && cardType.length) {
    const doesCardNumberMatchLength = cardType.length.includes(
      rawCardNumber.length,
    );
    if (doesCardNumberMatchLength) {
      const isLuhnValid = validateLuhn(rawCardNumber);
      if (isLuhnValid || !cardType.luhn) {
        return null;
      }
    }
  }
  return INVALID_CARD_NUMBER;
};

const getEmailErrors = email => {
  if (!email) {
    return EMPTY_EMAIL;
  }
  if (!validateEmail(email)) {
    return INVALID_EMAIL;
  }
  return null;
};

const getNameErrors = name => {
  if (!name) {
    return EMPTY_NAME;
  }
  return null;
};

const getExpiryDateErrors = expiryDate => {
  if (!expiryDate) {
    return EMPTY_EXPIRY_DATE;
  }
  const rawExpiryDate = expiryDate.replace(' / ', '').replace('/', '');
  if (rawExpiryDate.length === 4) {
    const month = rawExpiryDate.slice(0, 2);
    const year = `20${rawExpiryDate.slice(2, 4)}`;
    if (!MONTH_REGEX.test(month)) {
      return MONTH_OUT_OF_RANGE;
    }
    if (parseInt(year, 10) < new Date().getFullYear()) {
      return YEAR_OUT_OF_RANGE;
    }
    if (
      parseInt(year, 10) === new Date().getFullYear() &&
      parseInt(month, 10) < new Date().getMonth() + 1
    ) {
      return DATE_OUT_OF_RANGE;
    }
    return null;
  }
  return INVALID_EXPIRY_DATE;
};

const getCvcErrors = (cvc, cardType) => {
  if (!cvc) {
    return EMPTY_CVC;
  }
  if (cvc.length < 3) {
    return INVALID_CVC;
  }
  if (
    cardType &&
    cardType.cvcLength &&
    !cardType.cvcLength.includes(cvc.length)
  ) {
    return INVALID_CVC;
  }
  return null;
};

const getInstallmentsErrors = installments => {
  if (!installments) {
    return EMPTY_INSTALLMENTS;
  }
  return null;
};

const getDocTypeErrors = (docType, isMercadoPago) => {
  if (!docType && isMercadoPago) {
    return EMPTY_FIELD;
  }
  return null;
};

const getDocNumberErrors = (docNumber, isMercadoPago) => {
  if (!docNumber && isMercadoPago) {
    return EMPTY_FIELD;
  }
  return null;
};

export const validatePaymentData = (values, isMercadoPago) => {
  const errors = {};

  errors.email = getEmailErrors(values.email);

  if (!errors.email) {
    delete errors.email;
  }

  const rawCardNumber = values.cardNumber.replace(/\s/g, '');
  const cardType = getCardTypeByValue(rawCardNumber);

  errors.cardNumber = getCardNumberErros(rawCardNumber);

  if (!errors.cardNumber) {
    delete errors.cardNumber;
  }

  errors.name = getNameErrors(values.name);

  if (!errors.name) {
    delete errors.name;
  }

  errors.expiryDate = getExpiryDateErrors(values.expiryDate);

  if (!errors.expiryDate) {
    delete errors.expiryDate;
  }

  errors.cvc = getCvcErrors(values.cvc, cardType);

  if (!errors.cvc) {
    delete errors.cvc;
  }

  errors.installments = getInstallmentsErrors(values.installments);

  if (!errors.installments) {
    delete errors.installments;
  }

  errors.docType = getDocTypeErrors(values.docType, isMercadoPago);

  if (!errors.docType) {
    delete errors.docType;
  }

  errors.docNumber = getDocNumberErrors(values.docNumber, isMercadoPago);

  if (!errors.docNumber) {
    delete errors.docNumber;
  }

  return Promise.resolve(errors);
};
