import styled from 'styled-components';
import {shade, grayscale} from 'polished';

import {colors} from 'styles';

export const Button = styled.button`
  display: flex;
  flex: 1;
  flex-grow: 1;
  flex-shrink: 0;
  min-height: 50px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  background-color: ${props =>
    props.disabled ? grayscale(colors.background) : colors.background};
  border: 0;
  transition: background 0.5s;
  margin-bottom: 5px;
  :hover {
    background-color: ${shade(0.25, colors.background)};
  }
`;

export const ButtonTitle = styled.p`
  font-size: 1.1rem;
  font-weight: bold;
  color: ${colors.white};
  margin: 0;
`;
