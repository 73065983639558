import Immutable from 'seamless-immutable';

export const Types = {
  PAYMENT_REQUEST: 'payment/PAYMENT_REQUEST',
  PAYMENT_SUCCESS: 'payment/PAYMENT_SUCCESS',
  PAYMENT_FAIL: 'payment/PAYMENT_FAIL',
  PAYMENT_RESET: 'payment/PAYMENT_RESET',
};

const initialState = Immutable({
  reseted: false,
  processing: false,
  result: null,
  msg: null,
});

export const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case Types.PAYMENT_RESET: {
      return {
        ...initialState,
        reseted: true,
      };
    }
    case Types.PAYMENT_REQUEST: {
      return {
        ...state,
        processing: true,
        result: null,
        msg: null,
      };
    }
    case Types.PAYMENT_SUCCESS: {
      const {msg} = action.payload;
      return {
        ...state,
        processing: false,
        result: true,
        msg,
      };
    }
    case Types.PAYMENT_FAIL: {
      const {msg} = action.payload;
      return {
        ...state,
        processing: false,
        result: false,
        msg,
      };
    }
    default:
      return {
        ...state,
      };
  }
};

export const Creators = {
  paymentReset: () => ({
    type: Types.PAYMENT_RESET,
    payload: {},
  }),
  paymentRequest: () => ({
    type: Types.PAYMENT_REQUEST,
    payload: {},
  }),
  paymentSuccess: msg => ({
    type: Types.PAYMENT_SUCCESS,
    payload: {msg},
  }),
  paymentFail: msg => ({
    type: Types.PAYMENT_FAIL,
    payload: {msg},
  }),
};
