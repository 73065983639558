import React from 'react';
import {createRoot} from 'react-dom/client';

import {Provider} from 'react-redux';
import {PersistGate} from 'redux-persist/integration/react';

import 'locale/i18n';

import App from './App';

import store, {persistor} from './store';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,
);
