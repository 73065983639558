import React from 'react';

import LogoIService from 'assets/img/logo-iservice.png';

import {Container} from './styles';

export const HomeDefault = () => (
  <Container>
    <img
      src={LogoIService}
      alt="logo-iservice"
      className="animate__animated animate__pulse animate__infinite"
    />
  </Container>
);
