import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import pt from './pt/pt';
import en from './en/en';
import es from './es/es';

const resources = {
  pt,
  en,
  es,
};

const languageDetector = new LanguageDetector();
languageDetector.addDetector({
  name: 'my-detector',
  lookup() {
    if (navigator) {
      const lng = navigator.language;
      let normalizedLang = lng ? String(lng).slice(0, 2) : 'pt';
      if (
        normalizedLang !== 'pt' &&
        normalizedLang !== 'es' &&
        normalizedLang !== 'en'
      ) {
        normalizedLang = 'pt';
      }
      return normalizedLang;
    }
    return 'pt';
  },
  cacheUserLanguage() {},
});

i18n
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    debug: process.env.NODE_ENV === 'development',
    resources,
    ns: ['translation'],
    defaultNS: 'translation',
    nsSeparator: false,
    detection: {
      order: ['my-detector'],
      checkForSimilarInWhitelist: true,
    },
  });

export default i18n;
