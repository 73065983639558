import styled from 'styled-components';
import {shade} from 'polished';

import {colors} from 'styles';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colors.white};
  padding: 5px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${colors.white};
  padding: 1px 2px;
`;

export const ColLeft = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${colors.white};
  justify-content: flex-end;
  padding: 2px;
  align-items: center;
  flex-grow: 1;
`;

export const ColRight = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${colors.white};
  justify-content: flex-end;
  padding: 2px;
  align-items: center;
  width: auto;
  min-width: 90px;
`;

export const TotalText = styled.p`
  color: ${colors.textDark};
  font-size: 0.9rem;
  font-weight: normal;
  margin: 0;
`;

export const TotalTextBold = styled.p`
  color: ${colors.textDark};
  font-size: 1rem;
  font-weight: bold;
  margin: 0;
`;

export const TotalResult = styled.p`
  color: ${colors.background};
  font-size: 0.9rem;
  font-weight: normal;
  margin: 0;
`;

export const TotalResultBold = styled.p`
  color: ${colors.background};
  font-size: 1rem;
  font-weight: bold;
  margin: 0;
`;

export const PaymentButton = styled.button`
  display: flex;
  flex: 1;
  flex-grow: 1;
  min-height: 50px;
  justify-content: center;
  align-items: center;
  background-color: ${colors.background};
  border: 0;
  transition: background 0.5s;
  margin-bottom: 0px;
  cursor: pointer;
  :hover {
    background-color: ${shade(0.25, colors.background)};
  }
  :disabled {
    background-color: ${colors.grayDark};
  }
`;

export const PaymentButtonTitle = styled.p`
  font-size: 1.1rem;
  font-weight: bold;
  color: ${colors.white};
  margin: 0;
`;
