import accounting from 'accounting-js';

export const formatValue = value => {
  const newValue = parseFloat(String(value)).toFixed(2).replace('.', ',');
  return newValue;
};

export const truncateNumber = (value, precision = 2, separator = '.') => {
  const reg = new RegExp(`^-?\\d+(?:\\.\\d{0,${precision}})?`, 'g');
  const a = Number(parseFloat(value)).toString().match(reg)[0];
  const dot = a.indexOf(separator);
  if (dot === -1) {
    // integer, insert decimal dot and pad up zeros
    return `${a}.${'0'.repeat(precision)}`;
  }
  const b = precision - (a.length - dot) + 1;
  return b > 0 ? a + '0'.repeat(b) : a;
};

export const formatMoney = (number, precision = 2) =>
  accounting.formatMoney(number, {
    symbol: '',
    precision,
    thousand: '.',
    decimal: ',',
  });

export const unformat = (numberString, separator = ',') =>
  accounting.unformat(numberString, separator);

export const truncateAndFormatNumber = (value, precision = 2) =>
  formatMoney(truncateNumber(value, precision), precision);

export const truncateFloat = (value, precision = 2) =>
  unformat(truncateNumber(value, precision), '.');

export const roundAndFormatNumber = (value, precision) =>
  formatMoney(value, precision);

export const roundFloat = value => parseFloat(accounting.toFixed(value, 2));
