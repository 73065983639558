import Immutable from 'seamless-immutable';
import {isArray} from 'lodash';

import api from 'services/api';

export const Types = {
  ORDER_REQUEST: 'order/ORDER_REQUEST',
  ORDER_SUCCESS: 'order/ORDER_SUCCESS',
  ORDER_ERROR: 'order/ORDER_ERROR',
  ORDER_RESET: 'order/ORDER_RESET',
};

const initialState = Immutable({
  loading: true,
  refreshing: false,
  error: null,
  order: null,
});

export const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case Types.ORDER_REQUEST: {
      const {refresh} = action.payload;
      return {
        ...state,
        loading: !refresh,
        refreshing: refresh,
        error: null,
      };
    }
    case Types.ORDER_SUCCESS: {
      const {items} = action.payload.data;
      const listItems = isArray(items)
        ? items.map(item => ({
            ...item,
          }))
        : [];
      return {
        ...state,
        loading: false,
        refreshing: false,
        order: {
          items: listItems,
        },
      };
    }
    case Types.ORDER_ERROR: {
      const {error} = action.payload;
      return {
        ...state,
        loading: false,
        refreshing: false,
        error,
        order: null,
      };
    }
    case Types.ORDER_RESET: {
      return {
        ...state,
        ...initialState,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export const Creators = {
  orderRequest: refresh => ({
    type: Types.ORDER_REQUEST,
    payload: {refresh},
  }),
  orderSuccess: data => ({
    type: Types.ORDER_SUCCESS,
    payload: {
      data,
    },
  }),
  orderError: error => ({
    type: Types.ORDER_ERROR,
    payload: {
      error,
    },
  }),
  orderReset: () => ({
    type: Types.ORDER_RESET,
    payload: {},
  }),
};

export const orderRequest =
  ({refresh, pdv, mesa, cartao, conta, serie}) =>
  async dispatch => {
    dispatch(Creators.orderRequest(refresh));

    try {
      let cdmesa = mesa?.codigo;
      let tipo = mesa?.tipo;
      if (cartao && cartao.codigo && cartao.codigo !== '0') {
        cdmesa = cartao?.codigo;
        tipo = 'C';
      }

      let url = `/getstatuspedido/?pdv=${pdv.codigo}&cdmesa=${cdmesa}&tipomesa=${tipo}&webapp=${true}&serie=${serie}`;

      if (conta && conta.conta && conta.conta !== '0') {
        url = `/getstatuspedido/?pdv=${pdv.codigo}&cdmesa=${cdmesa}&tipomesa=${tipo}&tipo=${conta?.tipo}&conta=${conta?.conta}&webapp=${true}&serie=${serie}`;
      }

      const response = await api.get(url);

      if (response.data && response.data.status) {
        const {status} = response.data;

        if (status) {
          dispatch(Creators.orderSuccess({items: status}));
        } else {
          dispatch(Creators.orderError(true));
        }
      } else {
        dispatch(Creators.orderError(true));
      }
    } catch (e) {
      dispatch(Creators.orderError(true));
    }
  };
