import React, {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {Modal, ModalBody, ModalHeader, ModalFooter} from 'reactstrap';

import {useTranslation} from 'react-i18next';

import {
  Container,
  ModalContent,
  InputContainer,
  InputTable,
  InputLabel,
  ButtonsContainer,
  ButtonRight,
  ButtonTitleRight,
} from './styles';

export const ModalAskTable = ({isOpen, onCancel, onConfirm}) => {
  const inputRef = useRef();
  const {t} = useTranslation();
  const [text, setText] = useState('');

  useEffect(() => {
    if (!isOpen) {
      setText('');
    } else {
      setTimeout(() => {
        inputRef.current?.focus();
      }, 300);
    }
  }, [isOpen]);

  return (
    <Modal
      unmountOnClose
      fade
      centered
      size="lg"
      isOpen={isOpen}
      toggle={() => {
        onCancel();
      }}
      backdrop="static">
      <ModalHeader
        toggle={() => {
          onCancel();
        }}>
        {t('cart.confirmOrder')}
      </ModalHeader>
      <ModalBody>
        <Container>
          <ModalContent>
            <InputLabel>{t('cart.enterTableCode')}</InputLabel>
            <InputContainer>
              <InputTable
                innerRef={inputRef}
                autoFocus
                autoComplete="off"
                onChange={event => {
                  setText(String(event.target.value).replace(/\D/g, ''));
                }}
                value={text}
                placeholder=""
                type="tel"
                name="table"
                id="table"
                disabled={false}
                maxLength={4}
              />
            </InputContainer>
          </ModalContent>
        </Container>
      </ModalBody>
      <ModalFooter>
        <ButtonsContainer>
          <ButtonRight
            disabled={!text}
            onClick={() => {
              onConfirm(text);
            }}>
            <ButtonTitleRight>{t('actions.confirm')}</ButtonTitleRight>
          </ButtonRight>
        </ButtonsContainer>
      </ModalFooter>
    </Modal>
  );
};

ModalAskTable.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};
