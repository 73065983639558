import styled from 'styled-components';
import {Link} from 'react-router-dom';

import {colors} from 'styles';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: transparent;
  overflow-y: hidden;
`;

export const HeaderWave = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: auto;
  width: 100%;
  background-color: transparent;
  z-index: 1;
`;

export const ListView = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: ${colors.grayLight};
  overflow-y: auto;
  z-index: 2;
`;

export const Card = styled.div`
  display: flex;
  flex-shrink: 0;
  width: calc(50% - 5px);
  max-height: 120px;
  background-color: ${colors.white};
  border: 1px solid ${colors.blue};
  border-radius: 0.25rem;
  margin-top: 5px;
  margin-bottom: 5px;
  z-index: 3;
`;

export const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  flex: 1;
  flex-wrap: wrap;
  justify-content: center;
  align-content: flex-start;
  padding: 0 20px;
  ${Card}:nth-child(even) {
    margin-right: 5px;
  }
  ${Card}:nth-child(odd) {
    margin-left: 5px;
  }
`;

export const CardBody = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

export const CardTitle = styled.p`
  font-size: 1.1rem;
  color: ${colors.blue};
  margin-bottom: 0;
  text-align: center;
`;

export const CardButton = styled(Link)`
  display: flex;
  flex: 1;
  padding: 1.25rem;
  flex-direction: column;
  margin: 0 5px 0px 5px;
  height: auto;
  min-height: 100px;
  justify-content: center;
  align-items: center;
  padding: 0;
  border: 0;
  background-color: transparent;
  outline: 0;
  transition: all 0.5s ease;
  :focus {
    outline: 0;
  }
  .active {
    font-weight: bold;
  }
  :hover {
    text-decoration: none;
    ${CardTitle} {
      font-weight: bold;
    }
  }
`;

export const WeatherContainer = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  padding: 10px 20px;
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 0 10px 0;
  img {
    max-height: 80px;
  }
`;

export const BadgeContainer = styled.div`
  position: relative;
  width: 100%;
  height: 0;
`;

export const Badge = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: green;
  top: -15px;
  right: 5%;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  color: #fff;
  font-size: 1rem;
`;

export const VersionContainer = styled.div`
  font-size: 0.7rem;
  font-weight: bold;
  color: ${colors.textDark};
`;
