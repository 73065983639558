import {decode as atob} from 'base-64';

export const findTableInQRCodeToken = qrcode => {
  if (qrcode) {
    try {
      const stringSplit = qrcode.split('/home/');
      const token = stringSplit[stringSplit.length - 1];

      const tokenDecoded = atob(token);
      const obj = JSON.parse(tokenDecoded);

      if (obj?.mesa?.codigo) {
        return Promise.resolve(obj?.mesa?.codigo);
      }
      return Promise.reject(new Error('Table not found'));
    } catch (e) {
      return Promise.reject(e);
    }
  }
  return Promise.reject(new Error('Table not found'));
};
