import React, {useState, useEffect, useCallback} from 'react';
import PropTypes from 'prop-types';

import {useSelector} from 'react-redux';

export const MercadoPagoLoader = ({onLoad, isMercadoPago, children = null}) => {
  const [loaded, setLoaded] = useState(false);
  const {pagamento} = useSelector(reduxState => reduxState.config.config);

  const loadScript = useCallback(() => {
    const script = document.createElement('script');
    script.src = 'https://secure.mlstatic.com/sdk/javascript/v1/mercadopago.js';
    script.async = true;
    script.onload = () => {
      if (loaded === false) {
        window.Mercadopago.setPublishableKey(pagamento?.mercadopago?.publickey);
        onLoad();
      }
      setLoaded(true);
    };

    document.body.appendChild(script);
  }, [loaded, onLoad, pagamento]);

  useEffect(() => {
    if (loaded === false && isMercadoPago) {
      loadScript();
    }
  }, [loadScript, loaded, isMercadoPago]);

  if (!isMercadoPago) {
    return <>{children}</>;
  }
  if (isMercadoPago && loaded) {
    return <>{children}</>;
  }
  return null;
};

MercadoPagoLoader.propTypes = {
  onLoad: PropTypes.func.isRequired,
  isMercadoPago: PropTypes.bool.isRequired,
  children: PropTypes.node,
};
