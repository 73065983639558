import {createTransform} from 'redux-persist';

export const ConfigTransform = createTransform(
  inboundState => ({...inboundState}),
  outboundState => ({...outboundState, error: null, loading: false}),
  {whitelist: ['config']},
);

export const PedidoTransform = createTransform(
  inboundState => ({...inboundState}),
  outboundState => ({...outboundState, sending: false}),
  {whitelist: ['pedido']},
);

export const ProductTransform = createTransform(
  inboundState => ({...inboundState}),
  outboundState => ({
    ...outboundState,
    error: null,
    loading: true,
    done: false,
    refreshing: false,
    produtos: {
      cardapio: [],
      favoritos: [],
      alfabetica: [],
    },
  }),
  {whitelist: ['produto']},
);

export const ExtractTransform = createTransform(
  inboundState => ({...inboundState}),
  outboundState => ({
    ...outboundState,
    loading: true,
    refreshing: false,
    error: null,
    extract: null,
  }),
  {whitelist: ['extract']},
);

export const OrderTransform = createTransform(
  inboundState => ({...inboundState}),
  outboundState => ({
    ...outboundState,
    loading: true,
    refreshing: false,
    error: null,
    order: null,
  }),
  {whitelist: ['order']},
);

export const PaymentTransform = createTransform(
  inboundState => ({...inboundState}),
  outboundState => ({
    ...outboundState,
    reseted: false,
    processing: false,
    result: null,
    msg: null,
  }),
  {whitelist: ['payment']},
);
