const getCategories = products => {
  if (products && products.cardapio) {
    const categories = products.cardapio.map(product => ({
      codigo: product.codigo,
      nome: product.nome,
    }));
    return categories;
  }
  return [];
};

const getSubcategories = (products, categoryActive) => {
  if (products && products.cardapio && categoryActive) {
    const categories = [];
    products.cardapio.forEach(productLv1 => {
      if (productLv1.codigo === categoryActive.codigo) {
        const productsLv1 = productLv1.itens;
        if (productsLv1) {
          productsLv1.forEach(productLv2 => {
            if (productLv2.itens) {
              categories.push({
                codigo: productLv2.codigo,
                nome: productLv2.nome,
              });
            }
          });
        }
      }
    });
    return categories;
  }
  return [];
};

const getProducts = (products, category, subcategory, search) => {
  const productsAll = [];
  if (products && products.cardapio && category && !search) {
    const findCategory = products.cardapio.find(
      product => product.codigo === category.codigo,
    );
    if (findCategory) {
      const productsLv1 = findCategory.itens;
      productsLv1.forEach(productLv2 => {
        if (subcategory && productLv2.itens) {
          if (subcategory.codigo === productLv2.codigo) {
            productLv2.itens.forEach(productLv3 => {
              productsAll.push(productLv3);
            });
          }
        } else if (productLv2.itens) {
          productLv2.itens.forEach(productLv3 => {
            productsAll.push(productLv3);
          });
        } else {
          productsAll.push(productLv2);
        }
      });
      return productsAll;
    }
    return [];
  }
  if (products && products.cardapio) {
    products.cardapio.forEach(productLv1 => {
      const productsLv1 = productLv1.itens;
      productsLv1.forEach(productLv2 => {
        if (productLv2.itens) {
          productLv2.itens.forEach(productLv3 => {
            productsAll.push(productLv3);
          });
        } else {
          productsAll.push(productLv2);
        }
      });
    });
    if (search) {
      return productsAll.filter(
        d =>
          String(d.nome).toUpperCase().indexOf(String(search).toUpperCase()) >
          -1,
      );
    }
    return productsAll;
  }

  return [];
};

export default {getCategories, getSubcategories, getProducts};
