import styled from 'styled-components';
import {Input} from 'reactstrap';

import {colors} from 'styles';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: transparent;
  overflow-y: hidden;
`;

export const ListView = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  background-color: ${colors.grayLight};
  flex-direction: column;
  padding: 10px;
  overflow-y: auto;
`;

export const Content = styled.div`
  padding: 10px;
`;

export const InputContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0;
  margin: 10px 0;
`;

export const InputLabel = styled.span`
  font-size: 0.9rem;
  color: ${colors.textDark};
  font-weight: bold;
  margin-bottom: 0;
`;

export const GuestLastNameInput = styled(Input)`
  background-color: ${colors.white};
  border-color: ${colors.grayDark};
  border-radius: 0.25rem;
  transition: none;
  outline: 0;
  margin: 2px;
  height: 54px;
  transition: background 0.5s;
  font-size: 0.9rem;
  color: ${colors.textDark};
  ::placeholder {
    color: ${colors.grayDark};
    opacity: 0.5;
  }
  :focus {
    outline: 0;
    border-color: ${colors.grayDark};
    box-shadow: none;
  }
  :disabled {
    background-color: ${colors.gray};
  }
`;

export const CheckFooterContent = styled.div`
  display: flex;
  flex-direction: row;
`;
