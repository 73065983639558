import styled from 'styled-components';

import {colors} from 'styles';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: transparent;
  overflow-y: hidden;
`;

export const ListView = styled.div`
  display: flex;
  flex: 1;
  background-color: ${colors.grayLight};
  flex-direction: column;
  padding: 5px;
  overflow-y: auto;
`;

export const ListEmpty = styled.div`
  display: flex;
  flex: 1;
  background-color: transparent;
  justify-content: center;
  align-items: flex-start;
  p {
    font-size: 0.8rem;
    color: ${colors.textDark};
  }
`;
