import React, {useCallback, useEffect} from 'react';

import {useDispatch, useSelector} from 'react-redux';
import {orderRequest, Creators as OrderActions} from 'store/ducks/order';

import {useTranslation} from 'react-i18next';

import {Loading} from 'components/loading';
import {PullToRefresh} from 'components/pullToRefresh';

import {validateExpiryTime} from 'services/validateExpiryTime';

import Header from 'routes/partials/header';
import Main from 'routes/partials/main';
import Footer from 'routes/partials/footer';

import {Error} from 'components/error';
import {HeaderInfo} from 'components/headerInfo';

import {OrdersTable} from './components/ordersTable';

import {Container, ListView, ListEmpty} from './styles';

const Orders = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const {serie, pdv, mesa, cartao, conta} = useSelector(
    reduxState => reduxState.config.config,
  );
  const {loading, order, refreshing, error} = useSelector(state => state.order);

  const loadOrder = useCallback(
    (refresh = true) =>
      validateExpiryTime()
        .then(() =>
          dispatch(orderRequest({refresh, pdv, mesa, cartao, conta, serie})),
        )
        .catch(() => {}),
    [dispatch, pdv, mesa, cartao, conta, serie],
  );

  useEffect(() => {
    const refresh = false;
    loadOrder(refresh);

    const interval = setInterval(() => {
      loadOrder(true);
    }, 15000);

    return () => {
      clearInterval(interval);
      dispatch(OrderActions.orderReset());
    };
  }, [dispatch, loadOrder]);

  const handleRefresh = () => {
    const refresh = true;
    return new Promise(resolve => {
      if (!loading && !refreshing) {
        resolve(loadOrder(refresh));
      }
      resolve(true);
    });
  };

  return (
    <>
      <Header showHomeButton />
      <Main hasFooter hasHeader>
        <Container>
          <HeaderInfo />

          {loading && !refreshing ? <Loading /> : null}

          {error && !loading && !refreshing ? (
            <Error
              title={t('error.orderLoad')}
              onPress={() => {
                orderRequest(false);
              }}
            />
          ) : null}

          {!error ? (
            <>
              <PullToRefresh handleRefresh={handleRefresh} isPullable>
                <>
                  <ListView>
                    {order && order.items.length > 0 ? (
                      <OrdersTable data={order.items} />
                    ) : null}

                    {(!order || order.items.length === 0) &&
                    !loading &&
                    !refreshing ? (
                      <ListEmpty>
                        <p>{t('order.empty')}</p>
                      </ListEmpty>
                    ) : null}
                  </ListView>
                </>
              </PullToRefresh>
            </>
          ) : null}
        </Container>
      </Main>
      <Footer />
    </>
  );
};

export default Orders;
