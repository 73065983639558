import React from 'react';
import PropTypes from 'prop-types';

import {useTranslation} from 'react-i18next';

import {unformat, roundAndFormatNumber} from 'helpers/number';

import {
  Item,
  ItemNameContainer,
  ItemQtdContainer,
  ItemValueContainer,
  ItemText,
  ItemTextHeader,
} from './styles';

const ExtractItem = ({item, modStyle}) => {
  const total = unformat(item.valorbruto);
  return (
    <Item $modStyle={modStyle}>
      <ItemNameContainer>
        <ItemText>{item.nome}</ItemText>
      </ItemNameContainer>
      <ItemQtdContainer>
        <ItemText>{item.qtde}</ItemText>
      </ItemQtdContainer>
      <ItemValueContainer>
        <ItemText>{roundAndFormatNumber(total, 2)}</ItemText>
      </ItemValueContainer>
    </Item>
  );
};

ExtractItem.propTypes = {
  modStyle: PropTypes.bool.isRequired,
  item: PropTypes.shape({
    nome: PropTypes.string,
    qtde: PropTypes.string,
    valorbruto: PropTypes.string,
  }).isRequired,
};

export const ExtractTable = ({data = []}) => {
  const {t} = useTranslation();
  return (
    <>
      <Item>
        <ItemNameContainer>
          <ItemTextHeader>{t('extract.name')}</ItemTextHeader>
        </ItemNameContainer>
        <ItemQtdContainer>
          <ItemTextHeader>{t('extract.amount')}</ItemTextHeader>
        </ItemQtdContainer>
        <ItemValueContainer>
          <ItemTextHeader>{t('extract.value')}</ItemTextHeader>
        </ItemValueContainer>
      </Item>
      {data.map((item, index) => {
        const modStyle = index % 2 === 0;
        // eslint-disable-next-line react/no-array-index-key
        return <ExtractItem item={item} key={index} modStyle={modStyle} />;
      })}
    </>
  );
};

ExtractTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
};
