import React from 'react';

import {useTranslation} from 'react-i18next';

import {Container, Title} from './styles';

export const TagClosed = () => {
  const {t} = useTranslation();
  return (
    <Container>
      <Title>{t('error.closed')}</Title>
    </Container>
  );
};
