import {unformat} from 'helpers/number';

export const getInstallmentOptions = (total, valorminimo, parcelas) => {
  const options = [];
  const max = parseInt(unformat(total) / unformat(valorminimo), 10);
  const maxInstallments = max > 0 ? max : 1;

  const p = parcelas || 1;

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < p; i++) {
    if (i + 1 <= maxInstallments) {
      options.push({
        label: `${i + 1} X`,
        value: `${i + 1}`,
      });
    }
  }

  return options;
};
