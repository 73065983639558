import {format, add, fromUnixTime, isValid} from 'date-fns';
import {icons} from './icons';
import {langText} from './lang';

export default {
  getIcon(icon) {
    if (!icon) {
      return 'na';
    }
    const icoClass = icons[icon];
    if (icoClass) {
      return icoClass;
    }
    return 'na';
  },
  getUnits(unit) {
    if (unit === 'metric') {
      return {
        temp: '°C',
        speed: 'km/h',
      };
    }
    if (unit === 'imperial') {
      return {
        temp: '°F',
        speed: 'mph',
      };
    }
    return {temp: '', speed: ''};
  },
  formatDate(dte) {
    if (dte && isValid(new Date(dte))) {
      const unixToDate = fromUnixTime(dte);
      return format(new Date(unixToDate), 'dd MMMM y');
    }
    return '';
  },
  getLangs(lang) {
    return langText[lang] === undefined ? langText.en : langText[lang];
  },
  getNextDays(now) {
    const days = Array(4)
      .fill('')
      .map((_, index) =>
        format(add(new Date(now), {days: index + 1}), 'yyyy-MM-dd'),
      );
    return days;
  },
};
