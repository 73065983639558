import React, {useEffect, useCallback} from 'react';
import {pick} from 'lodash';

import {useTranslation} from 'react-i18next';
import {useParams, useNavigate} from 'react-router-dom';

import apiConfig from 'services/apiConfig';
import api from 'services/api';

import {useDispatch, useSelector} from 'react-redux';
import {Creators as ConfigActions} from 'store/ducks/config';
import {Creators as PedidoActions} from 'store/ducks/pedido';
import {Creators as ProdutoActions} from 'store/ducks/produto';

import {Alert} from 'providers/alert';

import {Loading} from 'components/loading';

import {validateToken} from 'helpers/tokenUrl';

import {Container} from './styles';

const Home = () => {
  const {t, i18n} = useTranslation();
  const {loading} = useSelector(state => state.config);
  const dispatch = useDispatch();
  const {token} = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      console.log('TOKEN', token);
    }
  }, [token]);

  const configRequest = useCallback(
    async hostInToken => {
      dispatch(ConfigActions.configRequest(token));

      try {
        const response = await apiConfig({
          method: 'GET',
          url: `${hostInToken}/config/?token=${token}&webapp=${true}&lng=${i18n.language}`,
        });

        try {
          const configData = pick(response.data, [
            'serie',
            'authToken',
            'conta',
            'host',
            'mesa',
            'cartao',
            'pdv',
            'theme',
            'pagamento',
            'logo',
            'estabelecimento',
            'dadoshotel',
            'horarios',
            'dadosconta',
            'informacoes',
            'expired',
            'bloquearpedido',
            'ocultarcardapio',
            'hideextrato',
            'permiteAptoNaMesa',
            'solicitarConfirmacaoMesa',
            'atribuirNumeroMesaNoCartao',
            'usamonitorapi',
            'produto',
          ]);

          api.defaults.baseURL = configData.host.api;
          api.defaults.headers.common.Authorization = `Bearer ${configData.authToken}`;

          dispatch(ConfigActions.configSuccess(configData));
          dispatch(PedidoActions.pedidoReset());
          dispatch(ProdutoActions.produtoReset());

          navigate('/check', {replace: true});
        } catch (e) {
          dispatch(ConfigActions.configError(true));

          Alert({
            text: t('error.config'),
            icon: 'error',
          });
        }
      } catch (e) {
        dispatch(ConfigActions.configError(true));

        Alert({
          text: e?.response?.data?.msg || t('error.config'),
          icon: 'error',
        });
      }
    },
    [dispatch, navigate, t, i18n.language, token],
  );

  useEffect(() => {
    if (token) {
      validateToken(token)
        .then(tokenDecode => {
          const host = tokenDecode.host.api;
          dispatch(ConfigActions.configSetHost(host));

          configRequest(host);
        })
        .catch(() => {
          alert('TOKEN INVALID');
        });
    } else {
      alert('TOKEN NOT PROVIDE');
    }
  }, [dispatch, token, configRequest]);

  return (
    <Container>
      {loading ? (
        <>
          <Loading title={t('home.loading')} />
        </>
      ) : null}
    </Container>
  );
};

export default Home;
