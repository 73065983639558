import styled from 'styled-components';
import {Input} from 'reactstrap';

import {colors} from 'styles';

export const Container = styled.div`
  display: flex;
  height: 50px;
  background-color: ${colors.white};
  padding: 6px 6px;
`;

export const InputContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  border: 1px solid ${colors.blue};
  align-items: center;
  justify-content: flex-start;
  background-color: ${colors.white};
  padding: 5px 10px 5px 10px;
  border-radius: 20px;
`;

export const SearchInput = styled(Input)`
  background-color: ${colors.white} !important;
  border: 0;
  border-radius: 0.25rem;
  height: 25px;
  outline: 0;
  margin: 2px;
  padding-right: 0px;
  transition: background 0.5s;
  font-size: 0.8rem;
  color: ${colors.textDark};
  ::placeholder {
    color: ${colors.grayDark};
    opacity: 0.5;
  }
  :focus {
    outline: 0;
    border: 0;
    box-shadow: none;
  }
  :disabled {
    background-color: ${colors.gray};
  }
`;
