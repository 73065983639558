import React, {useEffect, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {FaStar, FaComment} from 'react-icons/fa';

import {useTranslation} from 'react-i18next';

import {colors} from 'styles';

import {
  Container,
  SurveyItemButtons,
  SurveyButton,
  SurveyTitleContainer,
  SurveyTitle,
  SurveyObsContainer,
  SurveyObsInput,
  CommentButton,
} from './styles';

export const SurveyItem = ({
  title,
  code,
  value = null,
  index = null,
  answers = [],
  onClick,
  handleChangeComment,
  comment = '',
}) => {
  const [showObs, setShowObs] = useState(false);

  const obsRef = useRef();

  useEffect(() => {
    if (showObs && obsRef.current) {
      setTimeout(() => {
        if (obsRef.current) {
          obsRef.current.focus();
        }
      }, 300);
    }
  }, [showObs]);

  const {t} = useTranslation();

  const options = useMemo(
    () => answers.sort((a, b) => a.ordem - b.ordem),
    [answers],
  );

  return (
    <Container data-testid="survey-item-container">
      <SurveyTitle data-testid="survey-item-title">{title}</SurveyTitle>
      <SurveyTitleContainer>
        <CommentButton
          type="button"
          onClick={() => {
            setShowObs(!showObs);
          }}>
          <span>{t('survey.comment')}</span>
          <FaComment color={colors.grayDark} size={20} />
        </CommentButton>
      </SurveyTitleContainer>
      <SurveyItemButtons data-testid="survey-item-buttons">
        {options.map((a, i) => (
          <div key={a.codigo}>
            <>
              <SurveyButton
                type="button"
                active={value === a.codigo || i < index}
                onClick={() => {
                  onClick(code, i, a.codigo);
                }}
                color={colors.blue}>
                <FaStar color={colors.blue} size={30} />
              </SurveyButton>
            </>
          </div>
        ))}
      </SurveyItemButtons>
      {showObs ? (
        <SurveyObsContainer>
          <SurveyObsInput
            innerRef={obsRef}
            autoComplete="off"
            onChange={event => {
              handleChangeComment(code, event.target.value);
            }}
            value={comment}
            placeholder={t('survey.obs')}
            type="textarea"
            name="obs"
            id="obs"
            disabled={false}
            maxLength={150}
            rows={3}
          />
        </SurveyObsContainer>
      ) : null}
    </Container>
  );
};

SurveyItem.propTypes = {
  title: PropTypes.string.isRequired,
  code: PropTypes.number.isRequired,
  value: PropTypes.number,
  index: PropTypes.number,
  onClick: PropTypes.func.isRequired,
  handleChangeComment: PropTypes.func.isRequired,
  comment: PropTypes.string,
  answers: PropTypes.arrayOf(PropTypes.shape({})),
};
