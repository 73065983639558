import React, {useRef, useEffect, useState, useMemo} from 'react';

import {useParams, useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import {useSelector, useDispatch} from 'react-redux';
import {Creators as PaymentActions} from 'store/ducks/payment';

import {sendAnalyticsEvent} from 'helpers/analytics';

import {FaCheckCircle, FaExclamationCircle} from 'react-icons/fa';
import Main from 'routes/partials/main';
import HeaderBack from 'routes/partials/headerBack';

import {OverlayLoading} from 'components/overlayLoading';
import {HeaderInfo} from 'components/headerInfo';
import {IButton} from 'components/button';

import api from 'services/api';
import {format} from 'date-fns';
import {MD5} from 'crypto-js';

import {decodeToken} from 'helpers/tokenUrl';
import {CCCrypt} from 'helpers/cccrypt';
import {unformat} from 'helpers/number';
import {v4 as uuidv4} from 'uuid';

import {PaymentForm} from './components/paymentForm';

import {
  VALUE_TOTAL_EQUAL,
  VALUE_LESS_OR_EQUAL_TOTAL,
  VALUE_BIGGER_OR_EQUAL,
  VALUE_ANY,
} from './utils';

import {
  Container,
  ResultContainer,
  Result,
  ResultTitle,
  ResultSubtitle,
  ResultFooter,
} from './styles';

const Payment = () => {
  const {processing, result, msg, reseted} = useSelector(
    reduxState => reduxState.payment,
  );
  const {serie, pdv, mesa, conta, estabelecimento, pagamento} = useSelector(
    reduxState => reduxState.config.config,
  );
  const CAN_CHANGE_TOTAL = useMemo(() => pagamento.valorpermitido, [pagamento]);

  const dispatch = useDispatch();
  const {t, i18n} = useTranslation();
  const formikRef = useRef();
  const {token} = useParams();
  const navigate = useNavigate();

  const decode = decodeToken(token);

  const [total, setTotal] = useState(decode?.total);

  const originalValue = useMemo(() => decode?.total, [decode]);

  useEffect(() => {
    dispatch(PaymentActions.paymentReset());
  }, [dispatch]);

  const handleSubmitPayment = async values => {
    if (decode && !processing) {
      dispatch(PaymentActions.paymentRequest());

      const mpToken = values.token || null;
      const {email, name, cardNumber, expiryDate, cvc, brand, installments} =
        values;

      const nitdsl = MD5(
        JSON.stringify({
          total,
          date: format(new Date(), 'yyyy-MM-dd HH:mm'),
          serie,
          mesa: mesa?.codigo,
          conta: conta?.conta,
        }),
      ).toString();

      const cccrypt = CCCrypt(cardNumber, expiryDate, cvc);

      const data = {
        lng: i18n.language,
        valortotal: total,
        nitdsl,
        pdv,
        mesa,
        conta,
        estabelecimento,
        pagamento: {
          email,
          valor: total,
          cccrypt,
          ccnumber: '40000000400000044',
          valid: '032022',
          cvv: 123,
          brand,
          type: 'CREDIT',
          name,
          parcelas: installments,
          token: mpToken,
        },
      };

      try {
        const response = await api.post(
          `/tef/?webapp=${true}&serie=${serie}`,
          data,
        );

        if (response.data) {
          const {status, pms} = response.data;

          if (status) {
            if (pms?.status) {
              dispatch(
                PaymentActions.paymentSuccess(
                  response.data?.msg || t('payment.success'),
                ),
              );

              if (process.env.NODE_ENV !== 'development') {
                const purchaseData = {
                  transaction_id: uuidv4(),
                  affiliation: `Client-${serie}`,
                  payment_method: 'Credit Card',
                  value: unformat(total),
                  currency: 'BRL',
                };

                sendAnalyticsEvent(
                  'G-V0XL7GXEWR',
                  'payment_completed',
                  purchaseData,
                );
              }
            } else {
              dispatch(
                PaymentActions.paymentSuccess(
                  `${response.data?.msg}. ${t('payment.success2')}`,
                ),
              );
            }
          } else {
            dispatch(
              PaymentActions.paymentFail(
                response.data?.msg || t('payment.fail'),
              ),
            );
          }
        } else {
          dispatch(PaymentActions.paymentFail(t('payment.fail')));
        }
      } catch (e) {
        dispatch(PaymentActions.paymentFail(t('payment.fail')));
      }
    }
  };

  const handleBlur = () => {
    const numberTotal = unformat(total);
    const numberOriginalValue = unformat(originalValue);

    if (!total) {
      setTotal(originalValue);
    } else {
      if (CAN_CHANGE_TOTAL === VALUE_TOTAL_EQUAL) {
        if (numberTotal !== numberOriginalValue) {
          setTotal(originalValue);
        }
      }
      if (CAN_CHANGE_TOTAL === VALUE_LESS_OR_EQUAL_TOTAL) {
        if (numberTotal > numberOriginalValue) {
          setTotal(originalValue);
        }
      }
      if (CAN_CHANGE_TOTAL === VALUE_BIGGER_OR_EQUAL) {
        if (numberTotal < numberOriginalValue) {
          setTotal(originalValue);
        }
      }
      if (CAN_CHANGE_TOTAL === VALUE_ANY) {
        // ANY VALUE
      }
    }
  };

  if (!decode || !reseted) {
    return null;
  }

  return (
    <>
      <HeaderBack title={t('payment.title')} />
      <Main hasHeader>
        <Container>
          <HeaderInfo />
          {result === true ? (
            <ResultContainer>
              <Result>
                <FaCheckCircle color="#388e3c" size={40} />
                <ResultTitle>{t('payment.success')}</ResultTitle>
                <ResultSubtitle>{msg}</ResultSubtitle>
              </Result>
              <ResultFooter>
                <IButton
                  title={t('actions.back')}
                  onClick={() => {
                    navigate('/extract', {replace: true});
                  }}
                />
              </ResultFooter>
            </ResultContainer>
          ) : null}

          {result === false ? (
            <ResultContainer>
              <Result>
                <FaExclamationCircle color="#f9a825" size={40} />
                <ResultTitle>{t('payment.fail')}</ResultTitle>
                <ResultSubtitle>{msg}</ResultSubtitle>
              </Result>
              <ResultFooter>
                <IButton
                  title={t('actions.back')}
                  onClick={() => {
                    navigate('/extract', {replace: true});
                  }}
                />
              </ResultFooter>
            </ResultContainer>
          ) : null}

          {result === null ? (
            <>
              <PaymentForm
                ref={formikRef}
                total={total}
                handleSubmitPayment={data => {
                  handleSubmitPayment(data);
                }}
                handleChangeTotal={newValue => {
                  setTotal(newValue);
                }}
                handlePaymentValueBlur={() => {
                  handleBlur();
                }}
                canChangeTotal={CAN_CHANGE_TOTAL}
              />
              <OverlayLoading
                visible={processing}
                title={t('payment.processing')}
              />
            </>
          ) : null}
        </Container>
      </Main>
    </>
  );
};

export default Payment;
