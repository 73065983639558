import PropTypes from 'prop-types';

export const observacaoType = {
  codigo: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  nome: PropTypes.string,
};

export const produtoType = {
  codigo: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  nome: PropTypes.string,
  produto: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  itens: PropTypes.arrayOf(PropTypes.shape({})),
  img: PropTypes.string,
  descricao: PropTypes.string,
  obs: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape(observacaoType)),
    PropTypes.string,
  ]),
  combo: PropTypes.arrayOf(PropTypes.shape({})),
};

export const pedidoItemType = {
  uuid: PropTypes.string,
  destino: PropTypes.shape({}),
  produto: PropTypes.shape(produtoType),
  quantidade: PropTypes.number,
  pessoas: PropTypes.number,
  localizacao: PropTypes.string,
  observacao: PropTypes.string,
  listaObservacao: PropTypes.arrayOf(PropTypes.shape(observacaoType)),
};

export const extractType = {
  itens: PropTypes.arrayOf(PropTypes.shape({})),
  total: PropTypes.shape({
    qtditens: PropTypes.number,
    total: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    totalSelected: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
};
