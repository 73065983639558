import update from 'immutability-helper';
import {v4 as uuidv4} from 'uuid';

export const populateCombo = product => {
  const newCombo = product.combo.map(c => {
    const temp = c.itens.map(i => {
      const adicionais = i.adicionais
        ? i.adicionais.map(a => ({
            uuid: uuidv4(),
            ...a,
            qtd: a.qtd || 0,
          }))
        : [];
      const opcionais = i.opcionais
        ? i.opcionais.map(o => ({
            uuid: uuidv4(),
            ...o,
            active: o.active || false,
          }))
        : [];
      return {
        uuid: uuidv4(),
        ...i,
        adicionais,
        opcionais,
        qtd: i.obrigatorio ? 1 : i.qtd || 0,
        active: !!(i.obrigatorio || i.active),
      };
    });
    return {
      uuid: uuidv4(),
      ...c,
      itens: [...temp],
    };
  });
  return newCombo;
};

const checkIfIsActive = (
  comboCurrentState,
  comboLevelOneIndex,
  comboLevelTwoIndex,
) => {
  if (comboCurrentState && comboCurrentState[comboLevelOneIndex]) {
    return comboCurrentState[comboLevelOneIndex].itens[comboLevelTwoIndex]
      .active;
  }
  return false;
};

export const updateCombo = (
  comboCurrentState,
  comboLevelOneIndex,
  comboLevelTwoIndex,
  max,
) => {
  let qtd = 0;
  comboCurrentState[comboLevelOneIndex].itens.forEach(element => {
    if (element.active) {
      qtd += element.qtd;
    }
  });

  if (qtd < max) {
    // INCREASE
    const itemToUpdate =
      comboCurrentState[comboLevelOneIndex].itens[comboLevelTwoIndex];
    const newState = update(comboCurrentState, {
      [comboLevelOneIndex]: {
        itens: {
          [comboLevelTwoIndex]: {
            $set: {
              ...itemToUpdate,
              active: true,
              qtd: itemToUpdate.qtd + 1,
            },
          },
        },
      },
    });

    return newState;
  }
  if (
    checkIfIsActive(comboCurrentState, comboLevelOneIndex, comboLevelTwoIndex)
  ) {
    // IF ACTIVE
    if (qtd === max) {
      const itemToUpdate =
        comboCurrentState[comboLevelOneIndex].itens[comboLevelTwoIndex];
      const itemToReplaceIndex = comboCurrentState[
        comboLevelOneIndex
      ].itens.findIndex(
        el =>
          (el.obrigatorio === false ||
            (el.obrigatorio === true && el.qtd > 1)) &&
          el.active === true &&
          el.codigo !== itemToUpdate.codigo,
      );
      if (itemToReplaceIndex !== -1) {
        const itemToChange =
          comboCurrentState[comboLevelOneIndex].itens[itemToReplaceIndex];
        const newState = update(comboCurrentState, {
          [comboLevelOneIndex]: {
            itens: {
              [itemToReplaceIndex]: {
                $set: {
                  ...itemToChange,
                  active: false,
                  qtd: itemToChange.qtd - 1,
                },
              },
              [comboLevelTwoIndex]: {
                $set: {
                  ...itemToUpdate,
                  active: true,
                  qtd: itemToUpdate.qtd + 1,
                },
              },
            },
          },
        });

        return newState;
      }
      let active = false;
      let itemToChangeQtd = 0;
      if (itemToUpdate.obrigatorio) {
        active = true;
        itemToChangeQtd = 1;
      }
      const newState = update(comboCurrentState, {
        [comboLevelOneIndex]: {
          itens: {
            [comboLevelTwoIndex]: {
              $set: {...itemToUpdate, active, qtd: itemToChangeQtd},
            },
          },
        },
      });
      return newState;
    }
  } else {
    // IF NOT ACTIVE
    const itemToReplaceIndex = comboCurrentState[
      comboLevelOneIndex
    ].itens.findIndex(
      el =>
        (el.obrigatorio === false || (el.obrigatorio === true && el.qtd > 1)) &&
        el.active === true,
    );
    const itemToChange =
      comboCurrentState[comboLevelOneIndex].itens[itemToReplaceIndex];
    const itemToUpdate =
      comboCurrentState[comboLevelOneIndex].itens[comboLevelTwoIndex];
    let active = false;
    let itemToChangeQtd = 0;
    if (itemToChange.qtd > 1) {
      active = true;
      itemToChangeQtd = itemToChange.qtd - 1;
    }
    const newState = update(comboCurrentState, {
      [comboLevelOneIndex]: {
        itens: {
          [itemToReplaceIndex]: {
            $set: {...itemToChange, active, qtd: itemToChangeQtd},
          },
          [comboLevelTwoIndex]: {
            $set: {...itemToUpdate, active: true, qtd: 1},
          },
        },
      },
    });
    return newState;
  }
  return comboCurrentState;
};

export const updateAdditional = (
  additionalCurrentState,
  additionalIndex,
  action,
) => {
  const itemToChange = additionalCurrentState[additionalIndex];
  if (itemToChange) {
    let {qtd} = itemToChange;
    if (action === 'INCREASE' && qtd <= 9) {
      qtd += 1;
    } else if (action === 'DECREASE' && qtd >= 1) {
      qtd -= 1;
    }
    const newState = update(additionalCurrentState, {
      [additionalIndex]: {$set: {...itemToChange, qtd}},
    });
    return newState;
  }
  return additionalCurrentState;
};

export const transformAdditionals = additionals => {
  if (additionals) {
    return additionals.map(ad => ({
      codigo: ad.codigo ? ad.codigo : ad.cdproduto,
      nome: ad.nome ? ad.nome : ad.dsproduto,
      valor: ad.valor,
      uuid: ad.uuid || uuidv4(),
      qtd: ad.qtd || 0,
    }));
  }
  return [];
};
