import React, {useState, useCallback, useMemo} from 'react';

import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';

import Main from 'routes/partials/main';
import Header from 'routes/partials/header';

import {Alert} from 'providers/alert';

import {OverlayLoading} from 'components/overlayLoading';
import {IButton} from 'components/button';
import {HeaderInfo} from 'components/headerInfo';

import api from 'services/api';

import {SurveyItem} from './components/surveyItem';

import {Container, Content, SurveyFooter} from './styles';

const Survey = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {dadoshotel, conta, dadosconta, serie} = useSelector(
    state => state.config.config,
  );
  const pesquisa = dadoshotel?.pesquisa;

  const [loading, setLoading] = useState(false);
  const [answers, setAnswers] = useState(
    pesquisa
      ? pesquisa.map(p => ({
          code: p.codigo,
          value: null,
          index: null,
          comment: '',
        }))
      : [],
  );

  const handleChangeAnswers = useCallback(
    (code, index, value) => {
      const newState = answers.map(a => {
        if (a.code === code) {
          return {
            ...a,
            index,
            value,
          };
        }
        return {
          ...a,
        };
      });
      setAnswers(newState);
    },
    [answers],
  );

  const handleChangeComment = useCallback(
    (code, comment) => {
      const newState = answers.map(a => {
        if (a.code === code) {
          return {
            ...a,
            comment,
          };
        }
        return {
          ...a,
        };
      });
      setAnswers(newState);
    },
    [answers],
  );

  const handleSubmitAnswers = useCallback(async () => {
    setLoading(true);

    const {hospedes} = dadosconta;

    const data = {
      respostas: answers.map(a => ({
        codigo: a.code,
        resposta: a.value,
        obs: a.comment,
      })),
      origem: {
        conta,
        hospedes,
      },
    };

    try {
      await api.post(`/pesquisa/?webapp=${true}&serie=${serie}`, data);
      setLoading(false);

      Alert(
        {
          text: t('survey.success'),
          icon: 'success',
        },
        () => {
          navigate(-1);
        },
      );
    } catch (e) {
      Alert({
        text: t('survey.error'),
        icon: 'error',
      });
    }
  }, [answers, navigate, t, conta, dadosconta, serie]);

  const disabled = useMemo(() => !!answers.find(a => !a.value), [answers]);

  const options = useMemo(
    () => (pesquisa ? pesquisa.sort((a, b) => a.ordem - b.ordem) : []),
    [pesquisa],
  );

  if (!pesquisa) {
    return null;
  }
  return (
    <>
      <Header showHomeButton />
      <Main hasHeader>
        <Container>
          <HeaderInfo />
          <Content>
            {options.map(p => {
              const find = answers.find(a => a.code === p.codigo);
              return (
                <SurveyItem
                  key={p.codigo}
                  title={p.nome}
                  code={p.codigo}
                  answers={p.respostas}
                  value={find?.value}
                  index={find?.index}
                  comment={find?.comment}
                  onClick={(code, index, value) => {
                    handleChangeAnswers(code, index, value);
                  }}
                  handleChangeComment={(code, comment) => {
                    handleChangeComment(code, comment);
                  }}
                />
              );
            })}
          </Content>
          <SurveyFooter>
            <IButton
              disabled={disabled}
              title={t('actions.send')}
              onClick={() => {
                handleSubmitAnswers();
              }}
            />
          </SurveyFooter>
          <OverlayLoading visible={loading} title={t('actions.sending')} />
        </Container>
      </Main>
    </>
  );
};

export default Survey;
