import React from 'react';
import PropTypes from 'prop-types';

import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

import {
  Container,
  FooterHeader,
  FooterContent,
  FooterButtonLeft,
  FooterButtonRight,
  FooterButtonLeftTitle,
  FooterButtonRightTitle,
  TaxInfo,
} from './styles';

export const CartFooter = ({visible, total, onPressSend}) => {
  const {t} = useTranslation();
  const {pagamento, bloquearpedido} = useSelector(state => state.config.config);
  const {moeda} = pagamento;
  if (!visible) {
    return null;
  }
  return (
    <Container>
      <FooterHeader>
        <div>
          <p className="total">{t('cart.total')}: </p>
          <p className="value">
            {moeda} {total}
          </p>
        </div>
        <TaxInfo>{t('product.taxInfo')}</TaxInfo>
      </FooterHeader>
      <FooterContent>
        <FooterButtonLeft to="/products" replace>
          <FooterButtonLeftTitle>{t('cart.addMore')}</FooterButtonLeftTitle>
        </FooterButtonLeft>
        {!bloquearpedido ? (
          <FooterButtonRight
            onClick={() => {
              onPressSend();
            }}>
            <FooterButtonRightTitle>{t('cart.send')}</FooterButtonRightTitle>
          </FooterButtonRight>
        ) : null}
      </FooterContent>
    </Container>
  );
};

CartFooter.propTypes = {
  visible: PropTypes.bool.isRequired,
  total: PropTypes.string.isRequired,
  onPressSend: PropTypes.func.isRequired,
};
