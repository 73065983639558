import React from 'react';

import {useSelector} from 'react-redux';

import Main from 'routes/partials/main';
import Header from 'routes/partials/header';

import {HeaderInfo} from 'components/headerInfo';

import {Container, Content} from './styles';

const Info = () => {
  const {informacoes} = useSelector(state => state.config?.config);

  return (
    <>
      <Header showHomeButton />
      <Main hasHeader>
        <Container>
          <HeaderInfo />
          <Content dangerouslySetInnerHTML={{__html: informacoes}} />
        </Container>
      </Main>
    </>
  );
};

export default Info;
