import styled, {css} from 'styled-components';
import {Input} from 'reactstrap';

import {colors} from 'styles';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 20px 0;
`;

export const SurveyTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-self: stretch;
  align-items: center;
  justify-content: flex-end;
`;

export const SurveyTitle = styled.p`
  font-size: 1rem;
  color: ${colors.textDark};
  margin-bottom: 0;
`;

export const SurveyItemButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const SurveyButton = styled.button`
  position: relative;
  border: 0;
  /* background-color: #fff; */
  border-radius: 50%;
  margin: 0 10px;
  padding: 2px;
  opacity: 0.5;
  box-shadow: 1px 1px 2px #eee;

  ${({active, color}) =>
    active &&
    css`
      opacity: 1;
      &:after {
        position: absolute;
        width: 64%;
        height: 12%;
        left: 18%;
        border-radius: 50%;
        z-index: 0;
        bottom: 0;
        content: '';
        box-shadow: 0 12px 6px ${color};
      }
    `}
`;

export const CommentButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  background-color: ${colors.grayLight};
  margin-bottom: 0;
  span {
    color: ${colors.grayDark};
    margin-right: 10px;
  }
`;

export const SurveyObsContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  padding: 0;
  margin: 10px 0;
`;

export const SurveyObsInput = styled(Input)`
  background-color: ${colors.white};
  border-color: ${colors.grayDark};
  border-radius: 0.25rem;
  transition: none;
  height: 80px;
  outline: 0;
  margin: 2px;
  transition: background 0.5s;
  font-size: 0.9rem;
  color: ${colors.textDark};
  resize: none;
  ::placeholder {
    color: ${colors.grayDark};
    opacity: 0.5;
  }
  :focus {
    outline: 0;
    border-color: ${colors.grayDark};
    box-shadow: none;
  }
  :disabled {
    background-color: ${colors.gray};
  }
`;
