import React from 'react';
import PropTypes from 'prop-types';

import {limitString} from 'helpers/string';

import {ComboItemButton} from './styles';

export const ComboItem = ({
  title = '',
  onPress = () => {},
  badgeCount = 0,
  active = false,
  last = false,
}) => {
  const renderCounter = () => {
    if (badgeCount && badgeCount > 0) {
      return (
        <div className="badgeContent">
          <p className="badgeText">{badgeCount}</p>
        </div>
      );
    }
    return null;
  };

  return (
    <ComboItemButton
      type="button"
      className={`scrollItem ${active ? 'active' : ''} ${
        last ? 'scrollItemLast' : ''
      }`}
      onClick={() => {
        onPress();
      }}>
      {renderCounter()}
      <p className={`item ${active ? 'itemActive' : ''}`}>
        {limitString(title, 30)}
      </p>
    </ComboItemButton>
  );
};

ComboItem.propTypes = {
  title: PropTypes.string.isRequired,
  active: PropTypes.bool,
  last: PropTypes.bool,
  onPress: PropTypes.func.isRequired,
  badgeCount: PropTypes.number,
};

export default ComboItem;
