import React from 'react';
import PropTypes from 'prop-types';
import ReactWeather from 'components/weather/reactOpenWeather';
import 'weather-icons/css/weather-icons.min.css';

export const Weather = ({lat = null, lon = null, lang = null}) =>
  lat && lon ? (
    <>
      <ReactWeather
        forecast="5days"
        apikey="71209018a9c5ade997b8f69acfb701f1"
        type="geo"
        lat={lat}
        lon={lon}
        lang={lang}
      />
    </>
  ) : null;

Weather.propTypes = {
  lat: PropTypes.string,
  lon: PropTypes.string,
  lang: PropTypes.string,
};
