import toastr from 'toastr';
import './style.css';

toastr.options = {
  closeButton: false,
  debug: false,
  progressBar: true,
  preventDuplicates: true,
  positionClass: 'toast-middle-center',
  onclick: null,
  showDuration: '800',
  hideDuration: '800',
  timeOut: '2000',
  extendedTimeOut: '300',
  newestOnTop: true,
};

const functions = {
  success(message, title = '') {
    toastr.success(message, title);
  },
  info(message, title = '') {
    toastr.info(message, title);
  },
  warning(message, title = '') {
    toastr.warning(message, title);
  },
  error(message, title = 'Ops!') {
    toastr.error(message, title);
  },
};

export default functions;
