const gtag = (...args) => {
  if (typeof window !== 'undefined') {
    if (typeof window.gtag === 'undefined') {
      window.dataLayer = window.dataLayer || [];
      // eslint-disable-next-line no-shadow
      window.gtag = function gtag() {
        window.dataLayer.push(arguments);
      };
    }

    window.gtag(...args);
  }
};

export const loadGtag = trackingId => {
  const script = document.createElement('script');
  script.async = true;
  script.src = `https://www.googletagmanager.com/gtag/js?id=${trackingId}`;
  document.head.appendChild(script);

  script.onload = () => {
    gtag('js', new Date());

    // Initialize the tracker
    gtag('config', trackingId, {
      send_page_view: false,
      form_start: false,
    });
  };
};

export const sendAnalyticsEvent = (trackingId, eventName, eventParams = {}) => {
  console.log('SEND EVENT:', eventName, eventParams);

  gtag('js', new Date());

  gtag('event', eventName, {
    send_to: trackingId,
    ...eventParams,
  });
};

export const sendAnalyticsPurchaseEvent = (trackingId, purchaseData) => {
  console.log('SEND PURCHASE EVENT TO ANALYTICS', {
    ...purchaseData,
  });

  gtag('js', new Date());

  gtag('event', 'purchase', {
    send_to: trackingId,
    ...purchaseData,
  });
};
