import React, {useState, createRef, useEffect, useCallback} from 'react';

import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

import {useDispatch, useSelector} from 'react-redux';
import {Creators as ConfigActions} from 'store/ducks/config';

import Header from 'routes/partials/header';
import Main from 'routes/partials/main';

import {HeaderInfo} from 'components/headerInfo';
import {IButton} from 'components/button';

import {validateGuest} from 'services/validateGuest';

import {
  Container,
  ListView,
  Content,
  InputContainer,
  InputLabel,
  GuestLastNameInput,
  CheckFooterContent,
} from './styles';

const Check = () => {
  const [text, setText] = useState('');
  const textInput = createRef();

  const {t} = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {conta} = useSelector(state => state.config.config);
  const {configured} = useSelector(state => state.config);

  const redirectToDashboard = useCallback(() => {
    dispatch(ConfigActions.configSetValid());

    navigate('/dashboard', {replace: true});
  }, [dispatch, navigate]);

  const redirectToHome = useCallback(() => {
    dispatch(ConfigActions.configSetValid());

    navigate('/home', {replace: true});
  }, [dispatch, navigate]);

  useEffect(() => {
    if (textInput.current) {
      textInput.current.focus();
    }
  }, [textInput]);

  useEffect(() => {
    if (!configured) {
      redirectToHome();
    } else if (!conta || !conta?.conta) {
      redirectToDashboard();
    }
  }, [configured, conta, redirectToDashboard, redirectToHome]);

  const validateGuestLastName = useCallback(
    lastName => {
      validateGuest(lastName)
        .then(() => {
          redirectToDashboard();
        })
        .catch(() => {});
    },
    [redirectToDashboard],
  );

  if (!conta?.conta) {
    return null;
  }

  return (
    <>
      <Header />
      <Main hasHeader>
        <Container>
          <HeaderInfo />
          <ListView>
            <Content>
              <InputLabel>{t('cart.enterLastName')}</InputLabel>
              <InputContainer>
                <GuestLastNameInput
                  placeholder=""
                  innerRef={textInput}
                  autoFocus
                  autoComplete="off"
                  onChange={event => {
                    setText(event.target.value);
                  }}
                  value={text}
                  type="text"
                  name="guestLastName"
                  id="guestLastName"
                  disabled={false}
                  maxLength={40}
                />
              </InputContainer>
              <CheckFooterContent>
                <IButton
                  disabled={!text}
                  type="button"
                  onClick={() => {
                    validateGuestLastName(text);
                  }}
                  title={t('actions.confirm')}
                />
              </CheckFooterContent>
            </Content>
          </ListView>
        </Container>
      </Main>
    </>
  );
};

export default Check;
