import {createGlobalStyle} from 'styled-components';

import {colors} from 'styles';

// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
let vh = window.innerHeight * 0.01;
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty('--vh', `${vh}px`);

// We listen to the resize event
window.addEventListener('resize', () => {
  // We execute the same script as before
  vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
});

export const GlobalStyles = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  html, body {
    /* height: 100%; */
  }

  body {
    font: 400 14px Roboto, sans-serif;
    height: 100%;
    display: flex;
    flex-direction: column;
    background: ${({theme}) => theme.body};
    overflow: hidden;
    -webkit-font-smoothing: antialiased;
  }

  a {
    text-decoration: none !important
  }

  button {
    cursor: pointer;
  }

  .modal-content {
    border: 0;
    border-radius: 0;
  }

  .modal-header {
    display: flex;
    align-items: center;
    padding: 0.8rem;
  }

  .modal-body {
    padding: 0;
  }

  .modal-title {
    font-size: 1.1rem;
    color: ${colors.background};
  }

  button {
    :focus {
      outline: none;
    }
  }

  #root {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-height: 100%;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
  }

  .swal2-popup {
    border-radius: 0;
    padding-bottom: 0;
  }

  .swal2-container {
    .swal2-html-container {
      font-size: 1.1rem;
      color: ${colors.textDark}
    }
    .swal2-actions {
      display: flex;
      width: 100%;
      .swal2-cancel {
        flex: 1 !important;
        height: 60px;
        border-radius: 0
      }
      .swal2-confirm {
        flex: 1 !important;
        background-color: ${colors.background};
        height: 60px;
        border-radius: 0
      }
    }
  }
`;
