import i18next from 'locale/i18n';

export const ACCOUNT_TYPES = {
  UH: '1',
  FUNC: '2',
  AVULSA: '3',
  EMPRESA: '4',
  RESERVATION: '5',
  ADM: '6',
  PROP: '7',
  EVENT: '8',
  GRUPO: '9',
  PASSANTE: 'A',
  CARTAO: 'C',
  PEOPLE: 'P',
};

export const accountTypes = [
  {
    name: i18next.t('destino.apartamento'),
    initials: i18next.t('destino.apartamento_'),
    icon: 'hotel',
    type: ACCOUNT_TYPES.UH,
  },
  {
    name: i18next.t('destino.funcionario'),
    initials: i18next.t('destino.funcionario_'),
    icon: 'user-alt',
    type: ACCOUNT_TYPES.FUNC,
  },
  {
    name: i18next.t('destino.avulsas'),
    initials: i18next.t('destino.avulsas_'),
    icon: 'user-plus',
    type: ACCOUNT_TYPES.AVULSA,
  },
  {
    name: i18next.t('destino.empresas'),
    initials: i18next.t('destino.empresas_'),
    icon: 'building',
    type: ACCOUNT_TYPES.EMPRESA,
  },
  {
    name: i18next.t('destino.proprietarios'),
    initials: i18next.t('destino.proprietarios_'),
    icon: 'user-tie',
    type: ACCOUNT_TYPES.PROP,
  },
  {
    name: i18next.t('destino.eventos'),
    initials: i18next.t('destino.eventos_'),
    icon: 'glass-cheers',
    type: ACCOUNT_TYPES.EVENT,
  },
  {
    name: i18next.t('destino.grupos'),
    initials: i18next.t('destino.grupos_'),
    icon: 'users',
    type: ACCOUNT_TYPES.GRUPO,
  },
  {
    name: i18next.t('destino.passantes'),
    initials: i18next.t('destino.passantes_'),
    icon: 'running',
    type: ACCOUNT_TYPES.PASSANTE,
  },
  {
    name: i18next.t('destino.people'),
    initials: i18next.t('destino.people_'),
    icon: 'user',
    type: ACCOUNT_TYPES.PEOPLE,
  },
  {
    name: i18next.t('destino.cartao'),
    initials: i18next.t('destino.cartao_'),
    icon: 'id-card',
    type: ACCOUNT_TYPES.CARTAO,
  },
];

export const getAccountTypeName = destinoType => {
  if (destinoType) {
    const account = accountTypes.filter(
      d => String(d.type) === String(destinoType),
    );
    return account && account.length > 0 ? account[0].name : null;
  }
  return null;
};
