import store from 'store';

import {Alert} from 'providers/alert';

import i18n from 'locale/i18n';

const showAlert = () => {
  const error = i18n.t('error.lastNameNotMatch');
  Alert({
    text: error,
    icon: 'error',
  });
};

export const validateGuest = lastName =>
  new Promise((resolve, reject) => {
    const {dadosconta} = store.getState().config.config;

    const returnError = () => {
      showAlert();
      reject();
    };

    if (dadosconta && dadosconta.hospedes) {
      const {hospedes} = dadosconta;
      let findGuest = false;
      hospedes.forEach(h => {
        let findLastName = String(h).trim();
        findLastName = String(findLastName).split(' ');
        const len = findLastName.length;
        findLastName = findLastName[len - 1];

        if (
          String(findLastName).toUpperCase() === String(lastName).toUpperCase()
        ) {
          findGuest = true;
        }
      });

      if (findGuest) {
        resolve();
      } else {
        returnError();
      }
    } else {
      returnError();
    }
  });
