/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';

import {useTranslation} from 'react-i18next';

import {ComboItem} from './ComboItem';

import {ComboContainer} from './styles';

export const Combo = ({productCombo = null, handleAddComboItem = () => {}}) => {
  const {t} = useTranslation();

  const renderSelectionText = (min, max) => {
    const text =
      min > 1 ? t('product.comboSelectItems') : t('product.comboSelectItem');
    if (min === max) {
      return `${t('product.comboSelect')} ${min} ${text}`;
    }
    return `${t('product.comboSelectLimit')} ${max} ${text}`;
  };

  if (!productCombo) {
    return null;
  }

  return (
    <ComboContainer>
      {productCombo.map((comboItem, comboLevelOneIndex) => (
        <div
          className="scrollBox"
          key={`${comboItem.codigo}-${comboLevelOneIndex}`}>
          <p className="comboTitle">{comboItem.nome}</p>
          <p className="comboSubtitle">
            {renderSelectionText(comboItem.qtminima, comboItem.qtmaxima)}
          </p>
          <div className="scrollHorizontal">
            {comboItem.itens.map((combo, comboLevelTwoIndex) => (
              <ComboItem
                key={combo.codigo}
                title={combo.nome}
                active={combo.active}
                onPress={() => {
                  handleAddComboItem(
                    comboLevelOneIndex,
                    comboLevelTwoIndex,
                    comboItem.qtmaxima,
                  );
                }}
                last={comboItem.itens.length - 1 === comboLevelTwoIndex}
                badgeCount={combo.qtd}
              />
            ))}
          </div>
        </div>
      ))}
    </ComboContainer>
  );
};

Combo.propTypes = {
  productCombo: PropTypes.arrayOf(PropTypes.shape({})),
  handleAddComboItem: PropTypes.func,
};
