import styled from 'styled-components';

import {colors} from 'styles';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: transparent;
  overflow-y: hidden;
`;

export const Content = styled.div`
  display: flex;
  flex: 1;
  background-color: ${colors.grayLight};
  flex-direction: column;
  padding: 10px;
  overflow-y: auto;
`;

export const SurveyFooter = styled.div`
  display: flex;
  height: 80px;
  background-color: ${colors.grayLight};
  flex-direction: row;
  align-items: center;
  padding: 10px 10px;
`;
