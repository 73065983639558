import Immutable from 'seamless-immutable';
import {format} from 'date-fns';
import {isNil} from 'ramda';

export const Types = {
  CONFIG_SET_HOST: 'config/CONFIG_SET_HOST',
  CONFIG_RESET_DATE: 'config/CONFIG_RESET_DATE',
  CONFIG_REQUEST: 'config/CONFIG_REQUEST',
  CONFIG_SUCCESS: 'config/CONFIG_SUCCESS',
  CONFIG_ERROR: 'config/CONFIG_ERROR',
  CONFIG_SET_VALID: 'config/CONFIG_SET_VALID',
  CONFIG_UPDATE_TABLE: 'config/CONFIG_UPDATE_TABLE',
  CONFIG_UPDATE_CARD: 'config/CONFIG_UPDATE_CARD',
  CONFIG_UPDATE_ACCOUNT: 'config/CONFIG_UPDATE_ACCOUNT',
  CONFIG_UPDATE_CARD_AND_ACCOUNT: 'config/CONFIG_UPDATE_CARD_AND_ACCOUNT',
};

const initialState = Immutable({
  error: null,
  loading: false,
  configured: false,
  configuredDate: null,
  validated: false,
  config: {
    product: null,
    serie: null,
    expired: 30,
    authToken: null,
    pdv: null,
    mesa: null,
    cartao: null,
    conta: null,
    host: null,
    theme: null,
    pagamento: {selfcheckout: false, bandeiras: []},
    logo: null,
    dadoshotel: {},
    estabelecimento: null,
    horarios: {},
    dadosconta: {},
    informacoes: null,
    bloquearpedido: false,
    ocultarcardapio: false,
    hideextrato: false,
    permiteAptoNaMesa: false,
    cardOrAccountUpdated: false,
    solicitarConfirmacaoMesa: false,
    atribuirNumeroMesaNoCartao: false,
    usamonitorapi: false,
  },
});

export const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case Types.CONFIG_RESET_DATE: {
      return {
        ...state,
        configuredDate: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
      };
    }
    case Types.CONFIG_REQUEST: {
      return {
        ...state,
        loading: true,
        error: null,
        validated: false,
        configured: false,
        config: {
          ...initialState.config,
        },
      };
    }
    case Types.CONFIG_SET_HOST: {
      const {host} = action.payload;
      return {
        ...state,
        error: null,
        loading: false,
        config: {
          ...state.config,
          host: {
            api: host,
          },
        },
      };
    }
    case Types.CONFIG_SUCCESS: {
      const {
        produto,
        serie,
        pdv,
        mesa,
        cartao,
        conta,
        host,
        theme,
        authToken,
        pagamento,
        logo,
        estabelecimento,
        dadoshotel,
        horarios,
        dadosconta,
        informacoes,
        expired,
        bloquearpedido,
        ocultarcardapio,
        hideextrato,
        permiteAptoNaMesa,
        solicitarConfirmacaoMesa,
        atribuirNumeroMesaNoCartao,
        usamonitorapi,
      } = action.payload;
      return {
        ...state,
        error: null,
        loading: false,
        configured: true,
        configuredDate: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
        config: {
          product: parseInt(produto),
          serie,
          pdv,
          mesa,
          cartao,
          conta,
          host,
          theme,
          authToken,
          pagamento,
          logo,
          estabelecimento,
          dadoshotel,
          horarios,
          dadosconta,
          informacoes,
          expired,
          bloquearpedido: !isNil(bloquearpedido)
            ? Boolean(bloquearpedido)
            : false,
          ocultarcardapio: !isNil(ocultarcardapio)
            ? Boolean(ocultarcardapio)
            : false,
          hideextrato: !isNil(hideextrato) ? Boolean(hideextrato) : false,
          cardOrAccountUpdated: false,
          permiteAptoNaMesa: !isNil(permiteAptoNaMesa)
            ? Boolean(permiteAptoNaMesa)
            : false,
          solicitarConfirmacaoMesa: !isNil(solicitarConfirmacaoMesa)
            ? Boolean(solicitarConfirmacaoMesa)
            : false,
          atribuirNumeroMesaNoCartao: !isNil(atribuirNumeroMesaNoCartao)
            ? Boolean(atribuirNumeroMesaNoCartao)
            : false,
          usamonitorapi: !isNil(usamonitorapi) ? Boolean(usamonitorapi) : false,
        },
      };
    }
    case Types.CONFIG_ERROR: {
      const {error} = action.payload;
      return {
        ...state,
        error,
        loading: false,
        configured: false,
      };
    }
    case Types.CONFIG_SET_VALID: {
      return {
        ...state,
        validated: true,
      };
    }
    case Types.CONFIG_UPDATE_TABLE: {
      const {table} = action.payload;
      return {
        ...state,
        config: {
          ...state.config,
          mesa: table,
        },
      };
    }
    case Types.CONFIG_UPDATE_CARD: {
      const {card} = action.payload;
      return {
        ...state,
        config: {
          ...state.config,
          cartao: card,
          cardOrAccountUpdated: true,
        },
      };
    }
    case Types.CONFIG_UPDATE_ACCOUNT: {
      const {account} = action.payload;
      return {
        ...state,
        config: {
          ...state.config,
          conta: account,
          cardOrAccountUpdated: true,
        },
      };
    }
    case Types.CONFIG_UPDATE_CARD_AND_ACCOUNT: {
      const {card, account} = action.payload;
      return {
        ...state,
        config: {
          ...state.config,
          cartao: card,
          conta: account,
          cardOrAccountUpdated: true,
        },
      };
    }
    default:
      return {
        ...state,
      };
  }
};

export const Creators = {
  configSetHost: host => ({
    type: Types.CONFIG_SET_HOST,
    payload: {host},
  }),
  configResetDate: () => ({
    type: Types.CONFIG_RESET_DATE,
    payload: {},
  }),
  configRequest: token => ({
    type: Types.CONFIG_REQUEST,
    payload: {token},
  }),
  configSuccess: config => ({
    type: Types.CONFIG_SUCCESS,
    payload: {...config},
  }),
  configError: error => ({
    type: Types.CONFIG_ERROR,
    payload: {
      error,
    },
  }),
  configSetValid: () => ({
    type: Types.CONFIG_SET_VALID,
    payload: {},
  }),
  configUpdateTable: table => ({
    type: Types.CONFIG_UPDATE_TABLE,
    payload: {table},
  }),
  configUpdateCard: card => ({
    type: Types.CONFIG_UPDATE_CARD,
    payload: {card},
  }),
  configUpdateAccount: account => ({
    type: Types.CONFIG_UPDATE_ACCOUNT,
    payload: {account},
  }),
  configUpdateCardAndAccount: ({card, account}) => ({
    type: Types.CONFIG_UPDATE_CARD_AND_ACCOUNT,
    payload: {card, account},
  }),
};
