import styled from 'styled-components';

import {colors} from 'styles';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: transparent;
  overflow-y: hidden;
`;

export const Content = styled.div`
  display: flex;
  flex: 1;
  background-color: ${colors.grayLight};
  flex-direction: column;
  padding: 10px;
  overflow-y: auto;
`;
