import Immutable from 'seamless-immutable';
import {isArray} from 'lodash';
import {unformat, roundAndFormatNumber} from 'helpers/number';

export const Types = {
  EXTRACT_REQUEST: 'extract/EXTRACT_REQUEST',
  EXTRACT_SUCCESS: 'extract/EXTRACT_SUCCESS',
  EXTRACT_ERROR: 'extract/EXTRACT_ERROR',
  EXTRACT_RESET: 'extract/EXTRACT_RESET',
};

const calcTotal = (extractItens = []) => {
  let totalTaxa = 0;
  let totalBruto = 0;
  let totalDescontos = 0;
  let total = 0;
  extractItens.forEach(item => {
    const {valortaxa, valorbruto, valortotal, valordesconto} = item;

    totalTaxa += unformat(valortaxa);
    totalBruto += unformat(valorbruto);
    totalDescontos += unformat(valordesconto);
    total += unformat(valortotal);
  });
  return {
    totalTaxa: roundAndFormatNumber(totalTaxa, 2),
    totalBruto: roundAndFormatNumber(totalBruto, 2),
    totalDescontos: roundAndFormatNumber(totalDescontos, 2),
    total: roundAndFormatNumber(total, 2),
    qtditens: extractItens.length,
  };
};

const initialState = Immutable({
  loading: true,
  refreshing: false,
  error: null,
  extract: null,
});

export const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case Types.EXTRACT_REQUEST: {
      const {refresh} = action.payload;
      return {
        ...state,
        loading: !refresh,
        refreshing: refresh,
        error: null,
      };
    }
    case Types.EXTRACT_SUCCESS: {
      const {itens, total, bloquearpagamento} = action.payload.data;
      const listItems = isArray(itens)
        ? itens.map(item => ({
            codigo: item.codigo,
            unidademedida: item.unidademedida,
            nome: item.nome,
            cdlancamento: item.cdlancamento,
            conta: item.conta,
            qtde: roundAndFormatNumber(item.qtde, 3),
            valortaxa: roundAndFormatNumber(item.taxaserv, 2),
            valorbruto: roundAndFormatNumber(item.valorbruto, 2),
            valortotal: roundAndFormatNumber(item.valorcomtaxas, 2),
            valordesconto: roundAndFormatNumber(item.valordesconto, 2),
          }))
        : [];
      const totaisList = calcTotal(listItems);
      return {
        ...state,
        loading: false,
        refreshing: false,
        extract: {
          bloquearpagamento: !!bloquearpagamento,
          itens: listItems,
          total: {
            adiantamentos: roundAndFormatNumber(total?.adiantamentos || 0, 2),
            totalCortesias: roundAndFormatNumber(total?.totalcortesias || 0, 2),
            totalRetencoes: roundAndFormatNumber(total?.totalretencoes || 0, 2),
            ...totaisList,
          },
        },
      };
    }
    case Types.EXTRACT_ERROR: {
      const {error} = action.payload;
      return {
        ...state,
        loading: false,
        refreshing: false,
        error,
        extract: null,
      };
    }
    case Types.EXTRACT_RESET: {
      return {
        ...state,
        ...initialState,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export const Creators = {
  extractRequest: refresh => ({
    type: Types.EXTRACT_REQUEST,
    payload: {refresh},
  }),
  extractSuccess: data => ({
    type: Types.EXTRACT_SUCCESS,
    payload: {
      data,
    },
  }),
  extractError: error => ({
    type: Types.EXTRACT_ERROR,
    payload: {
      error,
    },
  }),
  extractReset: () => ({
    type: Types.EXTRACT_RESET,
    payload: {},
  }),
};
