import styled from 'styled-components';

import {colors} from 'styles';

export const ContainerButton = styled.div`
  display: flex;
  width: 100%;
  background-color: transparent;
  padding: 0;
  height: 100px;
  margin-bottom: 10px;
  border: 0;
  transition: opacity 0.5s;
  :focus {
    outline: none;
  }
  :hover {
    opacity: 0.8;
  }
`;

export const Container = styled.div`
  display: flex;
  flex: 1;
  background-color: white;
  padding: 5px;
  flex-direction: row;
  height: 100px;
  margin-bottom: 10px;
`;

export const ContainerImage = styled.div`
  display: flex;
  flex-shrink: 0;
  background-color: ${colors.white};
  height: 100%;
  width: 100px;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  padding: 5px;
`;

export const ContainerContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: ${colors.white};
  height: 100%;
  width: auto;
`;

export const ProductContentHeader = styled.div`
  display: flex;
  flex: 1;
  background-color: ${colors.white};
  flex-direction: column;
  justify-content: flex-start;
  padding: 0px 5px;
`;

export const ProductTitle = styled.h2`
  display: inline-block;
  font-size: 0.9rem;
  font-weight: bold;
  color: ${colors.textDark};
  margin-bottom: 2px;
  word-wrap: break-word;
  text-overflow: ellipsis;
  max-height: 2.2rem;
  overflow: hidden;
  text-align: left;
`;

export const ProductPrice = styled.p`
  font-size: 0.8rem;
  font-weight: normal;
  color: ${colors.textDark};
  margin-bottom: 0;
  text-align: left;
`;

export const ProductObs = styled.p`
  font-size: 0.9rem;
  font-weight: normal;
  color: ${colors.textDark};
  margin-bottom: 0;
  text-align: left;
`;

export const ProductContentFooter = styled.div`
  display: flex;
  min-height: 30px;
  height: auto;
  background-color: ${colors.white};
  justify-content: flex-end;
  align-items: center;
  padding: 2px 5px;
`;

export const ProductContentFooterButton = styled.button`
  border: 0;
  background-color: transparent;
  padding: 2px;
  width: 45px;
  height: auto;
`;

export const Imagem = styled.img`
  object-fit: cover;
  max-width: 100%;
  height: auto;
  max-height: 100%;
  border-radius: 10px;
`;

export const ComboImg = styled.img`
  width: 25px;
  height: 25px;
  position: absolute;
`;
