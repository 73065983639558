export const mpCreateToken = async ({
  email,
  name,
  expiryDate,
  cardNumber,
  cvc,
  installments,
  docType,
  docNumber,
  brand,
}) => {
  const $form = document.createElement('form');

  // EMAIL
  const input1 = document.createElement('input');
  input1.setAttribute('type', 'hidden');
  input1.setAttribute('id', 'email');
  input1.setAttribute('name', 'email');
  input1.setAttribute('value', email);
  input1.setAttribute('data-checkout', email);

  // CARD HOLDER
  const input2 = document.createElement('input');
  input2.setAttribute('type', 'hidden');
  input2.setAttribute('id', 'cardholderName');
  input2.setAttribute('name', 'cardholderName');
  input2.setAttribute('value', name);
  input2.setAttribute('data-checkout', 'cardholderName');

  // EXPIRATION MONTH
  const cardExpirationMonth = String(expiryDate).substr(0, 1);
  const input3 = document.createElement('input');
  input3.setAttribute('type', 'hidden');
  input3.setAttribute('id', 'cardExpirationMonth');
  input3.setAttribute('name', 'cardExpirationMonth');
  input3.setAttribute('value', cardExpirationMonth);
  input3.setAttribute('data-checkout', 'cardExpirationMonth');

  // EXPIRATION YEAR
  const cardExpirationYear = String(expiryDate).substr(3, 4);
  const input4 = document.createElement('input');
  input4.setAttribute('type', 'hidden');
  input4.setAttribute('id', 'cardExpirationYear');
  input4.setAttribute('name', 'cardExpirationYear');
  input4.setAttribute('value', cardExpirationYear);
  input4.setAttribute('data-checkout', 'cardExpirationYear');

  // CARD NUMBER
  const input5 = document.createElement('input');
  input5.setAttribute('type', 'hidden');
  input5.setAttribute('id', 'cardNumber');
  input5.setAttribute('name', 'cardNumber');
  input5.setAttribute('value', cardNumber);
  input5.setAttribute('data-checkout', 'cardNumber');

  // SECURITY CODE
  const input6 = document.createElement('input');
  input6.setAttribute('type', 'hidden');
  input6.setAttribute('id', 'securityCode');
  input6.setAttribute('name', 'securityCode');
  input6.setAttribute('value', cvc);
  input6.setAttribute('data-checkout', 'securityCode');

  // INSTALLMENTS
  const input7 = document.createElement('input');
  input7.setAttribute('type', 'hidden');
  input7.setAttribute('id', 'installments');
  input7.setAttribute('name', 'installments');
  input7.setAttribute('value', installments);
  input7.setAttribute('data-checkout', 'installments');

  // ISSUER
  const input8 = document.createElement('input');
  input8.setAttribute('type', 'hidden');
  input8.setAttribute('id', 'issuer');
  input8.setAttribute('name', 'issuer');
  input8.setAttribute('data-checkout', 'issuer');

  // DOCUMENT TYPE
  const input9 = document.createElement('input');
  input9.setAttribute('type', 'hidden');
  input9.setAttribute('id', 'docType');
  input9.setAttribute('name', 'docType');
  input9.setAttribute('value', docType);
  input9.setAttribute('data-checkout', 'docType');

  // DOCUMENT NUMBER
  const input10 = document.createElement('input');
  input10.setAttribute('type', 'hidden');
  input10.setAttribute('id', 'docNumber');
  input10.setAttribute('name', 'docNumber');
  input10.setAttribute('value', docNumber);
  input10.setAttribute('data-checkout', 'docNumber');

  // PAYMENT METHOD ID - BRAND
  const input11 = document.createElement('input');
  input11.setAttribute('type', 'hidden');
  input11.setAttribute('id', 'paymentMethodId');
  input11.setAttribute('name', 'paymentMethodId');
  input11.setAttribute('value', brand);
  input11.setAttribute('data-checkout', 'paymentMethodId');

  $form.appendChild(input1);
  $form.appendChild(input2);
  $form.appendChild(input3);
  $form.appendChild(input4);
  $form.appendChild(input5);
  $form.appendChild(input6);
  $form.appendChild(input7);
  $form.appendChild(input8);
  $form.appendChild(input9);
  $form.appendChild(input10);
  $form.appendChild(input11);

  return new Promise((resolve, reject) => {
    window.Mercadopago.createToken($form, (status, response) => {
      if (status === 200 || status === 201) {
        resolve(response.id);
      }
      reject(JSON.stringify(response, null, 4));
    });
  });
};
