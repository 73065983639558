import React, {useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {Col, FormGroup, Input} from 'reactstrap';
import {Field, useFormikContext} from 'formik';

import {mpGetIdentificationTypes} from './mercadoPago';

export const IdentificationTypes = () => {
  const [options, setOptions] = useState([]);
  const {t} = useTranslation();
  const {errors, touched, values, handleBlur, setFieldValue, handleChange} =
    useFormikContext();

  useEffect(() => {
    mpGetIdentificationTypes().then(mpOptions => {
      setOptions(mpOptions);
      if (mpOptions) {
        setFieldValue('docType', mpOptions[0].name);
      }
    });
  }, [setFieldValue]);

  return (
    <>
      <Col xs={6} sm={6} md={6}>
        <FormGroup>
          <Field name="docType">
            {({field}) => {
              const invalid = errors.docType && touched.docType;
              return (
                <Input
                  invalid={invalid}
                  className=""
                  autoComplete="off"
                  type="select"
                  placeholder={t('payment.docType')}
                  name={field.name}
                  value={values.docType || ''}
                  onChange={e => {
                    setFieldValue('docType', e.target.value);
                  }}
                  onBlur={handleBlur}
                  onFocus={() => {
                    setFieldValue('active', 'docType');
                  }}
                  data-checkout="docType">
                  {options.map(o => (
                    <option key={o.id} value={o.name}>
                      {o.name}
                    </option>
                  ))}
                </Input>
              );
            }}
          </Field>
        </FormGroup>
      </Col>

      <Col xs={6} sm={6} md={6}>
        <FormGroup>
          <Field name="docNumber">
            {({field}) => {
              const invalid = errors.docNumber && touched.docNumber;
              return (
                <Input
                  invalid={invalid}
                  className=""
                  autoComplete="off"
                  type="text"
                  placeholder={t('payment.docNumber')}
                  name={field.name}
                  value={values.docNumber || ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onFocus={() => {
                    setFieldValue('active', 'docNumber');
                  }}
                />
              );
            }}
          </Field>
        </FormGroup>
      </Col>
    </>
  );
};
