import React from 'react';
import PropTypes from 'prop-types';

import {useTranslation} from 'react-i18next';

import ErrorDinossaur from 'assets/img/error.png';

import {Container, Title, ErrorImage, Button} from './styles';

export const Error = ({title, onPress}) => {
  const {t} = useTranslation();
  return (
    <Container>
      <ErrorImage src={ErrorDinossaur} alt="error" />
      <Title>{title}</Title>
      <Button
        onClick={() => {
          onPress();
        }}>
        {t('error.tryAgain')}
      </Button>
    </Container>
  );
};

Error.propTypes = {
  title: PropTypes.string.isRequired,
  onPress: PropTypes.func.isRequired,
};
