import store from 'store';
import {parseISO, differenceInMinutes, isAfter} from 'date-fns';

import {Alert} from 'providers/alert';

import i18n from 'locale/i18n';

const showAlert = () => {
  const error = i18n.t('error.sessionExpired');
  Alert({
    text: error,
    icon: 'error',
  });
};

export const validateExpiryTime = () =>
  new Promise((resolve, reject) => {
    const {configuredDate, config} = store.getState().config;
    const {expired} = config;

    const firstDate = parseISO(configuredDate);

    const endDate = new Date();

    const dateDiff = differenceInMinutes(endDate, firstDate);
    const dateIsAfter = isAfter(endDate, firstDate);

    const minutesToExpired = parseInt(expired, 10);

    if (
      !minutesToExpired ||
      minutesToExpired < 1 ||
      Number.isNaN(minutesToExpired)
    ) {
      resolve();
    } else if (dateIsAfter && dateDiff <= minutesToExpired) {
      resolve();
    } else if (process.env.NODE_ENV === 'development') {
      resolve();
    } else {
      showAlert();
      reject();
    }
  });
