import {combineReducers} from 'redux';

import {reducer as produto} from './produto';
import {reducer as config} from './config';
import {reducer as pedido} from './pedido';
import {reducer as extract} from './extract';
import {reducer as payment} from './payment';
import {reducer as order} from './order';

export default combineReducers({
  produto,
  config,
  pedido,
  extract,
  order,
  payment,
});
