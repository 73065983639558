import api from 'services/api';
import {Creators} from '.';

export const extractRequest =
  ({refresh, pdv, mesa, cartao, conta, serie}) =>
  async dispatch => {
    dispatch(Creators.extractRequest(refresh));

    try {
      let codigoMesa = mesa?.codigo;
      let tipoMesa = mesa?.tipo;
      if (cartao && cartao.codigo && cartao.codigo !== '0') {
        codigoMesa = cartao?.codigo;
        tipoMesa = 'C';
      }

      let url = `/extrato/?pdv=${pdv.codigo}&cdmesa=${codigoMesa}&tipomesa=${tipoMesa}&imp=0&webapp=${true}&serie=${serie}`;

      if (conta && conta.conta && conta.conta !== '0') {
        url = `/extrato/?pdv=${pdv.codigo}&cdmesa=${codigoMesa}&tipomesa=${tipoMesa}&tipo=${conta?.tipo}&conta=${conta?.conta}&imp=0&webapp=${true}&serie=${serie}`;
      }

      // if (
      //   !codigoMesa &&
      //   conta &&
      //   conta.conta &&
      //   String(conta.conta) !== '0' &&
      //   String(conta.tipo) !== 'A' &&
      //   String(conta.tipo) !== '3'
      // ) {
      //   url = `/extrato/?pdv=${pdv.codigo}&cdmesa=${codigoMesa}&tipomesa=${tipoMesa}&tipo=${conta?.tipo}&conta=${conta?.conta}&imp=0&webapp=${true}&serie=${serie}`;
      // }

      const response = await api.get(url);

      if (response.data && response.data.extrato) {
        const {extrato} = response.data;

        if (extrato) {
          dispatch(Creators.extractSuccess(extrato));
        } else {
          dispatch(Creators.extractError(true));
        }
      } else {
        dispatch(Creators.extractError(true));
      }
    } catch (e) {
      dispatch(Creators.extractError(true));
    }
  };
