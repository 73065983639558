import React from 'react';
import PropTypes from 'prop-types';
import {FaPlus, FaMinus} from 'react-icons/fa';

import {colors} from 'styles';
import {AdditionalContainer} from './styles';

export const Additional = ({
  name,
  qtd = 0,
  price,
  moeda = '',
  increase,
  decrease,
}) => (
  <AdditionalContainer>
    <div className="textContainer">
      <p className="valueText">{name}</p>
      <p className="valuePrice">
        {moeda} {price}
      </p>
    </div>
    <div className="valueContainer">
      <p className="valueText">{qtd}</p>
    </div>
    <div className="buttonContainer">
      <button
        aria-label="increase"
        type="button"
        className="button"
        onClick={() => {
          increase();
        }}>
        <p className="buttonText">
          <FaPlus size={15} color={colors.white} />
        </p>
      </button>
      <button
        aria-label="decrease"
        type="button"
        className="button"
        onClick={() => {
          decrease();
        }}>
        <p className="buttonText">
          <FaMinus size={15} color={colors.white} />
        </p>
      </button>
    </div>
  </AdditionalContainer>
);

Additional.propTypes = {
  name: PropTypes.string.isRequired,
  qtd: PropTypes.number,
  moeda: PropTypes.string,
  price: PropTypes.string.isRequired,
  increase: PropTypes.func.isRequired,
  decrease: PropTypes.func.isRequired,
};
