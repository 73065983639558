import styled from 'styled-components';

import {colors} from 'styles';

export const HeaderContainer = styled.header`
  display: flex;
  position: fixed;
  width: 100%;
  top: 0;
  background-color: ${colors.gray};
  flex-direction: row;
  height: 54px;
  z-index: 99;
  align-items: center;
`;

export const HeaderButtonBackContainer = styled.a`
  display: flex;
  flex-shrink: 0;
  width: 70px;
  align-self: stretch;
  align-items: center;
  justify-content: center;
`;

export const HeaderTitleContainer = styled.div`
  display: flex;
  flex: 1;
  align-self: stretch;
  align-items: center;
  justify-content: flex-start;
`;

export const HeaderTitle = styled.p`
  margin: 0 0 0 10px;
  font-size: 1.2rem;
  color: ${colors.textDark};
  font-weight: bold;
`;
