import styled from 'styled-components';
import {shade} from 'polished';

import {colors} from 'styles';

export const ComboContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  .comboTitle {
    font-size: 1rem;
    color: ${colors.blue};
    margin-bottom: 2px;
  }
  .comboSubtitle {
    color: ${colors.grayDark};
    font-size: 0.8rem;
    margin-bottom: 3px;
  }
  .scrollHorizontal {
    margin-bottom: 5px;
    display: flex;
    flex-wrap: nowrap;
    min-height: 50px;
    height: auto;
    background-color: ${colors.white};
    overflow-x: auto;
    width: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    ::-webkit-scrollbar {
      display: none;
    }
  }
  .active {
    background-color: ${colors.blue};
    :hover {
      background-color: ${shade(0.25, colors.blue)};
    }
  }
  .itemActive {
    color: ${colors.white};
  }
`;

export const ComboItemButton = styled.button`
  position: relative;
  display: flex;
  flex: 0 0 auto;
  outline: 0;
  align-items: center;
  background-color: ${colors.white};
  border-color: ${colors.blue};
  border-radius: 5px;
  border-width: 2px;
  height: 45px;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
  padding: 5px;
  width: 150px;
  max-width: 100%;
  transition: all 0.5s;
  :focus {
    outline: 0;
  }
  .item {
    font-size: 0.7rem;
    margin-bottom: 0;
  }
  .badgeContent {
    position: absolute;
    top: 1px;
    right: 1px;
    width: 18px;
    height: 18px;
    justify-content: center;
    align-items: center;
    background-color: ${colors.white};
    border-radius: 50px;
    border: 2px solid ${colors.blue};
  }
  .badgeText {
    color: ${colors.grayDark};
    font-size: 0.7rem;
    font-weight: bold;
  }
`;
