import store from 'store';
import {getDay, setHours, setMinutes, isAfter, isBefore} from 'date-fns';

import {Alert} from 'providers/alert';

import i18n from 'locale/i18n';

const showAlert = () => {
  const error = i18n.t('error.hourOutRange');
  Alert({
    text: error,
    icon: 'error',
  });
};

const mapDaysOfWeek = {
  0: 'domingo',
  1: 'segunda',
  2: 'terca',
  3: 'quarta',
  4: 'quinta',
  5: 'sexta',
  6: 'sabado',
};

export const hourInRange = () => {
  const {horarios} = store.getState().config.config;

  if (horarios) {
    const now = new Date();
    const dayOfWeek = getDay(now);

    const periods = horarios[mapDaysOfWeek[dayOfWeek]];

    if (!periods || !Array.isArray(periods)) {
      return true;
    }

    let findHourInRange = false;

    periods.forEach(period => {
      const {inicio, fim} = period;

      const start = String(inicio).split(':');
      const end = String(fim).split(':');

      let startDate = setHours(now, start[0]);
      startDate = setMinutes(startDate, start[1]);

      let endDate = setHours(now, end[0]);
      endDate = setMinutes(endDate, end[1]);

      if (isAfter(now, startDate) && isBefore(now, endDate)) {
        findHourInRange = true;
      }
    });

    return findHourInRange;
  }
  return true;
};

export const validateHourOpen = () =>
  new Promise((resolve, reject) => {
    const valid = hourInRange();
    if (valid) {
      resolve();
    } else {
      showAlert();
      reject();
    }
  });
