import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {LazyLoadComponent} from 'react-lazy-load-image-component';

import {useSelector} from 'react-redux';

import NotFoundImage from 'assets/img/not-found.png';
import ComboIcon from 'assets/img/combo.png';

import {produtoType} from 'types';
import {
  ContainerButton,
  Container,
  ContainerImage,
  ContainerContent,
  ProductContentHeader,
  ProductTitleContainer,
  ProductTitle,
  ProductPrice,
  ProductContentFooter,
  ProductDescriptionContainer,
  ProductDescription,
  Imagem,
  ComboImg,
} from './styles';

export const ProductItem = ({product, onPress}) => {
  const {pagamento, bloquearpedido} = useSelector(state => state.config.config);

  const isCombo = useMemo(
    () => product.combo && product.combo.length > 0,
    [product],
  );

  const unavailable = useMemo(() => product.disponivel === false, [product]);

  if (unavailable) {
    return null;
  }

  return (
    <ContainerButton
      onClick={() => {
        if (!bloquearpedido) {
          onPress();
        }
      }}>
      <Container>
        {isCombo ? <ComboImg src={ComboIcon} alt="combo" /> : null}

        <ContainerImage>
          <LazyLoadComponent height="100%">
            <Imagem
              src={product.img ? product.img : NotFoundImage}
              alt={product.img}
            />
          </LazyLoadComponent>
        </ContainerImage>
        <ContainerContent>
          <ProductContentHeader>
            <ProductTitleContainer>
              <ProductTitle>{product.nome}</ProductTitle>
            </ProductTitleContainer>

            <ProductPrice>
              {pagamento.moeda} {product.valor}
            </ProductPrice>
          </ProductContentHeader>
          <ProductContentFooter>
            <ProductDescriptionContainer>
              <ProductDescription>{product.descricao}</ProductDescription>
            </ProductDescriptionContainer>
          </ProductContentFooter>
        </ContainerContent>
      </Container>
    </ContainerButton>
  );
};

ProductItem.propTypes = {
  product: PropTypes.shape(produtoType).isRequired,
  onPress: PropTypes.func.isRequired,
};
