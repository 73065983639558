import React from 'react';
import PropTypes from 'prop-types';

import Spinner from 'react-spinkit';

import {colors} from 'styles';
import {Container, Title} from './styles';

export const Loading = ({title = ''}) => (
  <Container>
    <Spinner name="ball-beat" color={colors.background} />
    <Title className="animate__animated animate__fadeIn animate__delay-2s">
      {title}
    </Title>
  </Container>
);

Loading.propTypes = {
  title: PropTypes.string,
};
