/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';

import {useTranslation} from 'react-i18next';

export const Card = props => {
  const {t} = useTranslation();
  const {acceptCards} = props;

  return (
    <>
      <Cards
        {...props}
        placeholders={{name: t('payment.yourName')}}
        locale={{valid: t('payment.expiry')}}
        acceptedCards={acceptCards || []}
      />
    </>
  );
};

Card.propTypes = {
  acceptCards: PropTypes.arrayOf(PropTypes.string),
};
