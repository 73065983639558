import styled from 'styled-components';

import {colors} from 'styles';

export const RefreshingContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 10px;
`;

export const PullingContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  .pull-title {
    font-size: 0.9rem;
    color: ${colors.textDark};
    margin-bottom: 0;
  }
`;

export const ScrollView = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-self: stretch;
  overflow-y: auto !important;
  -webkit-overflow-scrolling: touch;
  -webkit-overflow-y: auto !important;
`;
