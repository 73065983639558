import React, {Component} from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import {InputGroup, InputGroupText} from 'reactstrap';

import {colors} from 'styles';

export class InputNumber extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value || '',
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.value !== state.value) {
      return {
        value: props.value,
      };
    }
    return null;
  }

  render() {
    const {value} = this.state;
    const {moeda, disabled, handleChangeValue, handleBlur, handleFocus} =
      this.props;
    return (
      <>
        <InputGroup>
          <InputGroupText>
            <b>{moeda || ''}</b>
          </InputGroupText>

          <NumberFormat
            disabled={disabled || false}
            thousandSeparator="."
            decimalScale={2}
            fixedDecimalScale
            decimalSeparator=","
            allowNegative={false}
            value={value || ''}
            isNumericString={false}
            onValueChange={values => {
              handleChangeValue(values.formattedValue);
            }}
            onFocus={() => {
              handleFocus();
            }}
            onBlur={() => {
              handleBlur();
            }}
            displayType="input"
            style={{
              fontSize: '1.2rem',
              fontWeight: 'bold',
              color: colors.background,
              textAlign: 'right',
            }}
            className="form-control"
          />
        </InputGroup>
      </>
    );
  }
}

InputNumber.propTypes = {
  moeda: PropTypes.string,
  disabled: PropTypes.bool,
  handleChangeValue: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleFocus: PropTypes.func.isRequired,
  value: PropTypes.string,
};
