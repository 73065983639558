import React, {useEffect, useState} from 'react';
import {
  Route,
  BrowserRouter,
  Routes,
  useNavigate,
  Outlet,
} from 'react-router-dom';
import {useSelector} from 'react-redux';

import api from 'services/api';

import Check from 'pages/check';
import Dashboard from 'pages/dashboard';
import Home from 'pages/home';
import Info from 'pages/info';
import Products from 'pages/products';
import Cart from 'pages/cart';
import Extract from 'pages/extract';
import Payment from 'pages/payment';
import Chat from 'pages/chat';
import Survey from 'pages/survey';
import Orders from 'pages/orders';

import {GlobalStyles} from 'theme';
import {Tracker} from './Tracker';

import {NoMatch} from './partials/noMatch';
import {HomeDefault} from './partials/homeDefault';

import {generateToken} from './generateToken';

const RedirectHome = () => {
  const navigate = useNavigate();
  const paramsDemo = generateToken();

  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      setTimeout(() => {
        navigate(`/home/${paramsDemo}`, {replace: true});
      }, 1000);
    }
  }, [navigate, paramsDemo]);

  return <HomeDefault />;
};

const IServiceRoutes = () => {
  const [initialized, setInitialized] = useState(false);
  const {configured, validated, config} = useSelector(state => state.config);

  useEffect(() => {
    if (configured && config.host.api) {
      api.defaults.baseURL = config.host.api;
      api.defaults.headers.common.Authorization = `Bearer ${config.authToken}`;
    }
    setInitialized(true);
  }, [configured, config]);

  if (!initialized) {
    return null;
  }

  if (initialized && !configured) {
    return <RedirectHome />;
  }

  if (initialized && configured && validated) {
    return (
      <>
        <Tracker />
        <Outlet />
      </>
    );
  }

  return <RedirectHome />;
};

const AppNavigation = () => {
  useEffect(() => {
    console.log('ENV', process.env.NODE_ENV);
  }, []);

  return (
    <>
      <GlobalStyles />
      <BrowserRouter>
        <Routes>
          <Route path="/home/:token" element={<Home />} />
          <Route path="/check" element={<Check />} />

          <Route path="/" element={<IServiceRoutes />}>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/info" element={<Info />} />
            <Route path="/products" element={<Products />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/extract" element={<Extract />} />
            <Route path="/survey" element={<Survey />} />
            <Route path="/payment/:token" element={<Payment />} />
            <Route path="/chat" element={<Chat />} />
            <Route path="/orders" element={<Orders />} />

            <Route path="/" element={<RedirectHome />} />
          </Route>

          <Route path="*" element={<NoMatch />} />
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default AppNavigation;
