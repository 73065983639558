export default {
  white: '#FFF',
  grayLight: '#f2f2f2',
  gray: '#f4f4f4',
  // gray: '#dedfe0',
  grayDark: '#8a8a8a',
  textDark: '#757474',
  background: '#007dc3',
  blue: '#0485c9',
};
