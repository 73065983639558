import React, {useCallback, useEffect, useMemo} from 'react';

import {useDispatch, useSelector} from 'react-redux';
import {Creators as ExtractActions} from 'store/ducks/extract';
import {extractRequest} from 'store/ducks/extract/fetch-extract';

import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

import {Loading} from 'components/loading';
import {PullToRefresh} from 'components/pullToRefresh';

import {validateExpiryTime} from 'services/validateExpiryTime';

import {encodeData} from 'helpers/tokenUrl';
import {unformat, formatMoney} from 'helpers/number';
import {format} from 'date-fns';

import Header from 'routes/partials/header';
import Main from 'routes/partials/main';
import Footer from 'routes/partials/footer';

import {Error} from 'components/error';
import {HeaderInfo} from 'components/headerInfo';
import {ExtractFooter} from './components/extractFooter';
import {ExtractTable} from './components/extractTable';

import {Container, ListView, ListEmpty} from './styles';

const Extract = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {serie, pdv, mesa, cartao, conta, pagamento} = useSelector(
    reduxState => reduxState.config.config,
  );
  const {loading, extract, refreshing, error} = useSelector(
    state => state.extract,
  );

  const loadExtract = useCallback(
    (refresh = true) =>
      validateExpiryTime()
        .then(() =>
          dispatch(extractRequest({refresh, pdv, mesa, cartao, conta, serie})),
        )
        .catch(() => {}),
    [dispatch, pdv, mesa, cartao, conta, serie],
  );

  useEffect(() => {
    const refresh = false;
    loadExtract(refresh);

    return () => {
      dispatch(ExtractActions.extractReset());
    };
  }, [dispatch, loadExtract]);

  const handleRefresh = () => {
    const refresh = true;
    return new Promise(resolve => {
      if (!loading && !refreshing) {
        resolve(loadExtract(refresh));
      }
      resolve(true);
    });
  };

  const openPayment = () => {
    const {total, adiantamentos, totalCortesias, totalRetencoes} =
      extract.total;

    const data = {
      total: formatMoney(
        unformat(total) -
          unformat(adiantamentos) -
          unformat(totalCortesias) -
          unformat(totalRetencoes),
      ),
      date: format(new Date(), 'yyyy-MM-dd'),
      serie,
      mesa: mesa?.codigo,
      conta: conta?.conta,
    };

    try {
      const dataEncoded = encodeData(data);
      navigate(`/payment/${dataEncoded}`);
    } catch (e) {
      //
    }
  };

  const canPay = useMemo(() => {
    const protocol = window.location.protocol.replace(/:/g, '');
    const host = window.location.hostname;
    const isHml = String(host).includes('hml');

    if (host === 'localhost' || isHml || protocol === 'https') {
      return true;
    }
    return false;
  }, []);

  return (
    <>
      <Header showHomeButton />
      <Main hasFooter hasHeader>
        <Container>
          <HeaderInfo />

          {loading && !refreshing ? <Loading /> : null}

          {error && !loading && !refreshing ? (
            <Error
              title={t('error.extract')}
              onPress={() => {
                extractRequest(false);
              }}
            />
          ) : null}

          {!error ? (
            <>
              <PullToRefresh handleRefresh={handleRefresh} isPullable>
                <>
                  <ListView>
                    {extract && extract.itens.length > 0 ? (
                      <ExtractTable data={extract.itens} />
                    ) : null}

                    {(!extract || extract.itens.length === 0) &&
                    !loading &&
                    !refreshing ? (
                      <ListEmpty>
                        <p>{t('extract.empty')}</p>
                      </ListEmpty>
                    ) : null}
                  </ListView>
                </>
              </PullToRefresh>

              {extract && extract.total ? (
                <>
                  <ExtractFooter
                    showPayment={
                      pagamento?.selfcheckout &&
                      extract.itens.length > 0 &&
                      !extract.bloquearpagamento &&
                      canPay
                    }
                    onClick={() => {
                      openPayment();
                    }}
                    loading={loading || refreshing}
                    total={extract.total}
                  />
                </>
              ) : null}
            </>
          ) : null}
        </Container>
      </Main>
      <Footer />
    </>
  );
};

export default Extract;
