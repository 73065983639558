import styled from 'styled-components';

import {colors} from 'styles';

export const HeaderContainer = styled.header`
  display: flex;
  flex: 0;
  position: fixed;
  width: 100%;
  top: 0;
  background-color: ${colors.gray};
  flex-direction: row;
  height: 54px;
  z-index: 99;
`;

export const HeaderButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
  background-color: transparent;
  width: 55px;
  height: 100%;
`;

export const HeaderButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.grayDark};
  border-radius: 50%;
  height: 35px;
  width: 35px;
  border: 0;
  transition: background 0.5s;
  :hover {
    background-color: ${colors.grayDark};
  }
`;

export const HeaderImageContainer = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

export const ImageLogo = styled.img`
  max-height: 30px;
`;
