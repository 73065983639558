import styled from 'styled-components';
import {shade} from 'polished';

import {colors} from 'styles';
import {Input} from 'reactstrap';

export const Container = styled.div`
  padding: 0;
`;

export const ModalContent = styled.div`
  padding: 10px;
`;

export const InputContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0;
  margin: 10px 0;
`;

export const InputLabel = styled.span`
  font-size: 0.9rem;
  color: ${colors.textDark};
  font-weight: bold;
  margin-bottom: 0;
`;

export const InputTable = styled(Input)`
  background-color: ${colors.white};
  border-color: ${colors.grayDark};
  border-radius: 0.25rem;
  transition: none;
  outline: 0;
  margin: 2px;
  height: 54px;
  transition: background 0.5s;
  font-size: 0.9rem;
  color: ${colors.textDark};
  ::placeholder {
    color: ${colors.grayDark};
    opacity: 0.5;
  }
  :focus {
    outline: 0;
    border-color: ${colors.grayDark};
    box-shadow: none;
  }
  :disabled {
    background-color: ${colors.gray};
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex: 1;
  height: 60px;
  flex-direction: row;
  justify-content: space-between;
  background-color: ${colors.background};
  padding: 5px;
`;

export const ButtonRight = styled.button.attrs(props => ({
  $showLoading: props.$showLoading || false,
}))`
  display: flex;
  flex: 1;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  background-color: ${props =>
    props.$showLoading ? colors.gray : colors.background};
  border: 0;
  transition: background 0.5s;
  :hover {
    background-color: ${shade(0.25, colors.background)};
  }
`;

export const ButtonTitleRight = styled.p`
  font-size: 1.1rem;
  color: ${colors.white};
  font-weight: bold;
  margin: 0 5px 0 0;
`;

export const CameraContainer = styled.div`
  margin: auto;
  width: 100%;
  position: relative;
  height: auto;
  min-height: 400px;
`;

export const QRCodeMask = styled.div`
  top: 0;
  left: 0;
  z-index: 1;
  box-sizing: border-box;
  border: 20px solid rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 0 5px rgba(255, 0, 0, 0.5);
  position: absolute;
  width: 100%;
  height: 100%;
`;

export const QRCodeLabel = styled.span`
  font-size: 0.9rem;
  color: ${colors.textDark};
  font-weight: bold;
`;

export const LoadingContainer = styled.div`
  display: flex;
  margin: auto;
  flex: 1;
  width: 100%;
  justify-content: center;
  align-items: center;
  min-height: 400px;
`;
