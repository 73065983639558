import axios from 'axios';

const api = axios.create({
  baseURL: 'http://localhost',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json; charset=UTF-8',
  },
  timeout: 30000,
});

export const changeApiUrl = url => {
  api.defaults.baseURL = url;
};

export default api;
