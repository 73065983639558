import {decode as atob, encode as btoa} from 'base-64';

export const encodeData = data => {
  try {
    return btoa(JSON.stringify(data));
  } catch (e) {
    return null;
  }
};

export const decodeToken = token => {
  try {
    const decode = atob(token);
    return JSON.parse(decode);
  } catch (e) {
    return null;
  }
};

export const validateToken = token => {
  const tokenDecoded = decodeToken(token);

  if (tokenDecoded && tokenDecoded?.host?.api && tokenDecoded.pdv) {
    const urlStart = String(tokenDecoded.host.api).substring(0, 4);

    if (urlStart === 'http') {
      return Promise.resolve(tokenDecoded);
    }
    console.error('Invalid API url');
    return Promise.reject();
  }
  return Promise.reject();
};
