import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {LazyLoadComponent} from 'react-lazy-load-image-component';

import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import {FaEdit, FaTrash} from 'react-icons/fa';

import {unformat, roundAndFormatNumber} from 'helpers/number';

import NotFoundImage from 'assets/img/not-found.png';
import ComboIcon from 'assets/img/combo.png';

import {colors} from 'styles';

import {pedidoItemType} from 'types';

import {
  ContainerButton,
  Container,
  ContainerImage,
  ContainerContent,
  ProductContentHeader,
  ProductTitle,
  ProductPrice,
  ProductObs,
  ProductContentFooter,
  ProductContentFooterButton,
  Imagem,
  ComboImg,
} from './styles';

export const CartItem = ({pedido, onPressEdit, onPressRemove}) => {
  const {moeda} = useSelector(state => state.config.config.pagamento);
  const {produto} = pedido;
  const {t} = useTranslation();

  const isCombo = useMemo(
    () => produto.combo && produto.combo.length > 0,
    [produto],
  );

  const priceTitle = `${pedido.quantidade} x ${moeda || ''} ${
    produto.valor
  } = ${moeda || ''} ${roundAndFormatNumber(
    unformat(produto.valor) * pedido.quantidade,
  )}`;

  const additionalTitle = useMemo(() => {
    let qtd = 0;
    let value = 0;
    if (produto.adicionais && produto.adicionais.length > 0) {
      produto.adicionais.forEach(ad => {
        if (ad.qtd > 0) {
          qtd += ad.qtd;
          value += unformat(ad.valor) * ad.qtd;
        }
      });
    }
    if (qtd > 0) {
      return `${qtd} ${t('cart.additionals')} = ${
        moeda || ''
      } ${roundAndFormatNumber(value)}`;
    }
    return null;
  }, [produto, moeda, t]);

  return (
    <ContainerButton>
      <Container>
        {isCombo ? <ComboImg src={ComboIcon} alt="combo" /> : null}

        <ContainerImage>
          <LazyLoadComponent height="100%">
            <Imagem
              src={produto.img ? produto.img : NotFoundImage}
              alt={produto.img}
            />
          </LazyLoadComponent>
        </ContainerImage>
        <ContainerContent>
          <ProductContentHeader>
            <ProductTitle>{produto.nome}</ProductTitle>
            <ProductPrice>{priceTitle}</ProductPrice>
            <ProductObs>{pedido.observacao}</ProductObs>
            {additionalTitle ? (
              <ProductObs>{additionalTitle}</ProductObs>
            ) : null}
          </ProductContentHeader>
          <ProductContentFooter>
            <ProductContentFooterButton
              onClick={() => {
                onPressEdit();
              }}>
              <FaEdit color={colors.background} size={20} />
            </ProductContentFooterButton>
            <ProductContentFooterButton
              onClick={() => {
                onPressRemove();
              }}>
              <FaTrash color={colors.background} size={20} />
            </ProductContentFooterButton>
          </ProductContentFooter>
        </ContainerContent>
      </Container>
    </ContainerButton>
  );
};

CartItem.propTypes = {
  pedido: PropTypes.shape(pedidoItemType).isRequired,
  onPressEdit: PropTypes.func.isRequired,
  onPressRemove: PropTypes.func.isRequired,
};
