import styled from 'styled-components';
import {Link} from 'react-router-dom';

import {colors} from 'styles';

export const FooterContainer = styled.footer`
  display: flex;
  flex: 0;
  position: fixed;
  width: 100%;
  bottom: 0;
  background-color: ${colors.gray};
  flex-direction: row;
  height: 64px;
  border-top: 1px solid rgba(117, 116, 116, 0.5);
  z-index: 999;
`;

export const FooterContent = styled.div`
  display: flex;
  height: 100%;
  flex: 1;
  justify-content: space-around;
  align-items: center;
`;

export const FooterButtonTitle = styled.p`
  font-weight: bold;
  font-size: 1rem;
  color: ${colors.grayDark};
  margin-bottom: 0;
`;

export const FooterButton = styled(Link)`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 0 5px 0 5px;
  height: 54px;
  justify-content: center;
  align-items: center;
  padding: 0;
  border: 0;
  background-color: transparent;
  outline: 0;
  transition: all 0.5s ease;
  :focus {
    outline: 0;
  }
  .active {
    font-weight: bold;
    font-size: 1rem;
    color: ${colors.blue};
  }
  :hover {
    text-decoration: none;
    ${FooterButtonTitle} {
      font-weight: bold;
      font-size: 1rem;
    }
  }
`;

export const BadgeContainer = styled.div`
  position: relative;
  width: 100%;
  height: 0;
`;

export const Badge = styled.div`
  position: absolute;
  background: green;
  top: 0;
  right: 20%;
  width: 12px;
  height: 12px;
  border-radius: 50%;
`;
