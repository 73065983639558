import styled from 'styled-components';
import {shade} from 'polished';

import {colors} from 'styles';
import {Input} from 'reactstrap';

export const Container = styled.div`
  padding: 0;
`;

export const ModalContent = styled.div`
  padding: 10px;
`;

export const InputContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0;
  margin: 10px 0;
`;

export const InputLabel = styled.span`
  font-size: 0.9rem;
  color: ${colors.textDark};
  font-weight: bold;
  margin-bottom: 0;
`;

export const InputTable = styled(Input)`
  background-color: ${colors.white};
  border-color: ${colors.grayDark};
  border-radius: 0.25rem;
  transition: none;
  outline: 0;
  margin: 2px;
  height: 54px;
  transition: background 0.5s;
  font-size: 0.9rem;
  color: ${colors.textDark};
  ::placeholder {
    color: ${colors.grayDark};
    opacity: 0.5;
  }
  :focus {
    outline: 0;
    border-color: ${colors.grayDark};
    box-shadow: none;
  }
  :disabled {
    background-color: ${colors.gray};
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex: 1;
  height: 60px;
  flex-direction: row;
  justify-content: space-between;
  background-color: ${colors.background};
  padding: 5px;
`;

export const ButtonRight = styled.button`
  display: flex;
  flex: 1;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  background-color: ${colors.background};
  border: 0;
  transition: background 0.5s;
  :hover {
    background-color: ${shade(0.25, colors.background)};
  }
`;

export const ButtonTitleRight = styled.p`
  font-size: 1.1rem;
  color: ${colors.white};
  font-weight: bold;
  margin: 0 5px 0 0;
`;
