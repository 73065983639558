import React from 'react';
import {useTranslation} from 'react-i18next';
import {FaShoppingCart, FaUtensils, FaFileAlt, FaClock} from 'react-icons/fa';

import {useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';

import {colors} from 'styles';

import {
  FooterContainer,
  FooterContent,
  FooterButton,
  FooterButtonTitle,
  BadgeContainer,
  Badge,
} from './styles';

const Footer = () => {
  const location = useLocation();
  const {pathname} = location;

  const pedidos = useSelector(state => state.pedido.itens);
  const {ocultarcardapio, hideextrato, bloquearpedido, usamonitorapi} =
    useSelector(state => state.config.config);
  const {t} = useTranslation();

  return (
    <FooterContainer>
      <FooterContent>
        {!ocultarcardapio ? (
          <FooterButton to="/products" replace>
            <FaUtensils
              color={pathname === '/products' ? colors.blue : colors.grayDark}
              size={20}
            />
            <FooterButtonTitle
              className={pathname === '/products' ? 'active' : ''}>
              {t('layout.product')}
            </FooterButtonTitle>
          </FooterButton>
        ) : null}

        {!ocultarcardapio && !bloquearpedido ? (
          <FooterButton to="/cart" replace>
            {pedidos && pedidos.length > 0 ? (
              <BadgeContainer>
                <Badge className="animate__animated animate__headShake" />
              </BadgeContainer>
            ) : null}

            <FaShoppingCart
              color={pathname === '/cart' ? colors.blue : colors.grayDark}
              size={20}
            />
            <FooterButtonTitle className={pathname === '/cart' ? 'active' : ''}>
              {t('layout.cart')}
            </FooterButtonTitle>
          </FooterButton>
        ) : null}

        {!hideextrato ? (
          <FooterButton to="/extract" replace>
            <FaFileAlt
              color={pathname === '/extract' ? colors.blue : colors.grayDark}
              size={20}
            />
            <FooterButtonTitle
              className={pathname === '/extract' ? 'active' : ''}>
              {t('layout.extract')}
            </FooterButtonTitle>
          </FooterButton>
        ) : null}

        {usamonitorapi ? (
          <FooterButton to="/orders" replace>
            <FaClock
              color={pathname === '/orders' ? colors.blue : colors.grayDark}
              size={20}
            />
            <FooterButtonTitle
              className={pathname === '/orders' ? 'active' : ''}>
              {t('layout.orders')}
            </FooterButtonTitle>
          </FooterButton>
        ) : null}
      </FooterContent>
    </FooterContainer>
  );
};

export default Footer;
