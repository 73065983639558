import Payment from 'payment';

function clearNumber(value = '') {
  return value.replace(/\D+/g, '');
}

export function formatCreditCardNumber(value) {
  if (!value) {
    return value;
  }

  const nextValue = Payment.fns.formatCardNumber(value);

  return nextValue.trim();
}

export function formatCVC(value, cardNumber) {
  const clearValue = clearNumber(value);
  let maxLength = 4;

  if (cardNumber) {
    const issuer = Payment.fns.cardType(cardNumber);
    maxLength = issuer === 'amex' ? 4 : 3;
  }

  return clearValue.slice(0, maxLength);
}

export function formatExpirationDate(value) {
  const clearValue = clearNumber(value);

  if (clearValue.length >= 3) {
    return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 4)}`;
  }

  return clearValue;
}
