import {format} from 'date-fns';
import {v4 as uuidv4} from 'uuid';
import {unformat, roundAndFormatNumber} from 'helpers/number';

const createOrder = ({
  product,
  amount,
  observation,
  observations,
  account,
  additionals,
}) => ({
  produto: {
    ...product,
    adicionais: additionals || [],
  },
  datetime: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
  quantidade: amount,
  uuid: uuidv4(),
  pessoas: 1,
  localizacao: '',
  observacao: observation,
  observacoes: observations,
  destino: account,
});

const updateOrder = (
  order,
  {product, amount, observation, observations, additionals},
) => ({
  ...order,
  produto: {
    ...product,
    adicionais: additionals || [],
  },
  quantidade: amount,
  observacao: observation,
  observacoes: observations || [],
});

const getTotalFormated = pedidos => {
  let total = 0;
  if (pedidos) {
    pedidos.forEach(pedido => {
      total += unformat(pedido.produto.valor) * pedido.quantidade;

      if (pedido.produto.adicionais && pedido.produto.adicionais.length > 0) {
        let totalAdditionals = 0;
        pedido.produto.adicionais.forEach(additional => {
          totalAdditionals += unformat(additional.valor) * additional.qtd;
        });
        total += totalAdditionals;
      }
    });
    return roundAndFormatNumber(total);
  }
  return '00,00';
};

export default {createOrder, updateOrder, getTotalFormated};
