import styled, {css} from 'styled-components';

import {colors} from 'styles';

export const Container = styled.div`
  display: flex;
  flex-wrap: nowrap;
  height: 50px;
  background-color: ${colors.white};
  overflow-x: scroll;
  width: 100vw;
  border-top: 0.5px solid rgba(117, 116, 116, 0.3);
  border-bottom: 0.5px solid rgba(117, 116, 116, 0.3);
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const CategoryContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
`;

export const CategoryTitle = styled.p`
  display: flex;
  font-size: 0.9rem;
  font-weight: 500;
  color: ${colors.textDark};
  margin: 0;
  ${props =>
    props.$active &&
    css`
      color: ${colors.background};
      text-decoration: underline;
      text-underline-position: under;
    `}
`;

export const CategoryButton = styled.button`
  display: flex;
  flex: 0 0 auto;
  border: 0;
  outline: 0;
  background-color: transparent;
  margin: 0 10px;
  padding: 0;
  justify-content: center;
  align-items: center;
  min-width: 30px;
  max-width: 100%;
  white-space: nowrap;
  transition: all 0.5s;
  :focus {
    outline: 0;
  }
  :hover {
    ${CategoryTitle} {
      color: ${colors.background};
    }
  }
`;
