import styled from 'styled-components';

import {colors} from 'styles';

export const Container = styled.div`
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  height: 35px;
  background-color: ${colors.white};
  justify-content: center;
  align-items: center;
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
`;

export const HeaderGroup = styled.div`
  display: flex;
  flex-direction: row;
`;

export const HeaderTitle = styled.p`
  font-size: 1.1rem;
  font-weight: 200;
  color: ${colors.background};
  margin: 0;
  margin-right: 5px;
`;

export const HeaderValue = styled.p`
  font-size: 1.1rem;
  font-weight: bold;
  color: ${colors.background};
  margin: 0;
  margin-right: 5px;
`;

export const Title = styled.p`
  font-size: 0.9rem;
  color: ${colors.background};
  font-weight: bold;
  margin: 0;
`;
