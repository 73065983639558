import Immutable from 'seamless-immutable';

export const Types = {
  PRODUTO_REQUEST: 'produto/PRODUTO_REQUEST',
  PRODUTO_SUCCESS: 'produto/PRODUTO_SUCCESS',
  PRODUTO_ERROR: 'produto/PRODUTO_ERROR',
  PRODUTO_RESET: 'produto/PRODUTO_RESET',
};

const initialState = Immutable({
  error: null,
  loading: true,
  done: false,
  refreshing: false,
  produtos: {
    cardapio: [],
    favoritos: [],
    alfabetica: [],
  },
});

export const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case Types.PRODUTO_REQUEST: {
      const {refresh} = action.payload;
      return {
        ...state,
        loading: !refresh,
        refreshing: refresh,
        error: null,
      };
    }
    case Types.PRODUTO_SUCCESS: {
      const {cardapio, favoritos, alfabetica} = action.payload.produtos;
      return {
        ...state,
        error: null,
        loading: false,
        refreshing: false,
        done: true,
        produtos: {
          cardapio,
          favoritos,
          alfabetica,
        },
      };
    }
    case Types.PRODUTO_ERROR: {
      const {error} = action.payload;
      return {
        ...state,
        error,
        loading: false,
        refreshing: false,
        produtos: {
          cardapio: [],
          favoritos: [],
          alfabetica: [],
        },
      };
    }
    case Types.PRODUTO_RESET:
      return {
        ...state,
        ...initialState,
      };
    default:
      return {
        ...state,
      };
  }
};

export const Creators = {
  produtoRequest: refresh => ({
    type: Types.PRODUTO_REQUEST,
    payload: {refresh},
  }),
  produtoSuccess: produtos => ({
    type: Types.PRODUTO_SUCCESS,
    payload: {
      produtos,
    },
  }),
  produtoError: error => ({
    type: Types.PRODUTO_ERROR,
    payload: {
      error,
    },
  }),
  produtoReset: () => ({
    type: Types.PRODUTO_RESET,
    payload: {},
  }),
};
