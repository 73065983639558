import styled from 'styled-components';

import {colors} from 'styles';

export const Container = styled.div`
  display: flex;
  background-color: ${colors.grayLight};
  flex: 1;
  flex-direction: column;
  padding: 20px;
`;

export const CardContainer = styled.div`
  margin-bottom: 20px;
`;

export const PaymentFormContent = styled.div`
  input,
  select {
    background-color: ${colors.white};
    border-color: ${colors.grayDark};
    border-radius: 0.25rem;
    transition: none;
    height: 50px;
    outline: 0;
    margin: 2px;
    transition: background 0.5s;
    font-size: 0.9rem;
    color: ${colors.textDark};
    resize: none;
    ::placeholder {
      color: ${colors.grayDark};
      opacity: 0.5;
    }
    :focus {
      outline: 0;
      border-color: ${colors.grayDark};
      box-shadow: none;
    }
    :disabled {
      background-color: ${colors.gray};
    }
  }
  .form-control.is-invalid {
    border-color: #dc3545;
  }
  .form-control.is-invalid:focus {
    box-shadow: none;
  }
  .form-check-input {
    height: 20px !important;
    width: 20px !important;
  }
  .form-check-label {
    font-size: 1.1rem;
  }
`;

export const PaymentFooterContent = styled.div`
  display: flex;
  flex-direction: row;
`;
