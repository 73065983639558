import styled, {css} from 'styled-components';

import {colors} from 'styles';

export const Item = styled.a`
  display: flex;
  flex-shrink: 0;
  background-color: ${colors.white};
  flex-direction: row;
  align-items: center;
  height: 30px;
  margin-top: 0.5px;
  ${props =>
    props.$modStyle &&
    css`
      background: ${colors.grayLight};
    `}
`;

export const ItemNameContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: auto;
  align-items: center;
  background-color: ${colors.transparent};
  justify-content: center;
  align-items: flex-start;
  padding: 2px;
  overflow: hidden;
`;

export const ItemQtdContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 20%;
  align-items: center;
  background-color: ${colors.transparent};
  justify-content: center;
  align-items: flex-end;
  padding: 2px;
`;

export const ItemValueContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 25%;
  align-items: center;
  background-color: ${colors.transparent};
  justify-content: center;
  align-items: flex-end;
  padding: 2px;
`;

export const ItemTextHeader = styled.p`
  color: ${colors.textDark};
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 0;
`;

export const ItemText = styled.p`
  color: ${colors.textDark};
  font-size: 0.9rem;
  margin-bottom: 0;
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;
