import styled, {css} from 'styled-components';

export const Container = styled.main`
  display: flex;
  flex-direction: column;
  align-self: stretch;
  flex: 1;
  width: 100%;
  max-width: 1024px;
  margin-right: auto;
  margin-left: auto;
  overflow: hidden !important;
  ${props =>
    props.$hasHeader &&
    css`
      margin-top: 54px;
    `}
  ${props =>
    props.$hasFooter &&
    css`
      margin-bottom: 64px;
    `}
`;
