function secondsToHMS(seconds) {
  const hours = String(Math.floor(seconds / 3600)).padStart(2, '0');
  const minutes = String(Math.floor((seconds % 3600) / 60)).padStart(2, '0');
  const remainingSeconds = String(seconds % 60).padStart(2, '0');

  return {hours, minutes, seconds: remainingSeconds};
}

const getStatusColor = orderStatus => {
  const status = String(orderStatus)
    .toUpperCase()
    .replace(/[^a-zA-Z]+/g, '');
  if (status === 'AGUARDANDO') {
    return 'warning';
  }
  if (status === 'INICIADO') {
    return 'info';
  }
  if (status === 'FINALIZADO') {
    return 'primary';
  }
  if (status === 'ENTREGUE') {
    return 'success';
  }
  return 'secondary';
};

export {secondsToHMS, getStatusColor};
