import styled from 'styled-components';

import TagImg from 'assets/img/tag.png';

import {colors} from 'styles';

export const Container = styled.div`
  height: 25px;
  width: 110px;
  background-image: url(${TagImg});
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  display: flex;
  flex: 1 0 100%;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  padding: 5px;
`;

export const Title = styled.p`
  color: ${colors.white};
  font-size: 0.8rem;
  font-weight: bold;
  margin: 0;
`;
