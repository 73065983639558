export const validateComboLevels = productCombo =>
  new Promise((resolve, reject) => {
    const errors = [];
    productCombo.forEach(combo => {
      const min = combo.qtminima;
      const max = combo.qtmaxima;
      let count = 0;
      combo.itens.forEach(element => {
        if (element.active) {
          count += element.qtd;
        }
      });
      if (count < min || count > max) {
        errors.push(combo.nome);
      }
    });
    if (errors.length === 0) {
      resolve();
    } else {
      reject(errors);
    }
  });
