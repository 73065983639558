import styled from 'styled-components';
import {shade} from 'polished';

import {Link} from 'react-router-dom';

import {colors} from 'styles';

export const Container = styled.div`
  flex-direction: column;
  height: 120px;
  background-color: ${colors.grayLight};
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
`;

export const FooterHeader = styled.div`
  display: flex;
  flex-direction: column;
  height: 40px;
  align-self: stretch;
  justify-content: center;
  align-items: flex-end;
  div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .total {
    margin-bottom: 0;
    font-size: 1rem;
    font-weight: 300;
    color: ${colors.textDark};
    margin-right: 10px;
  }
  .value {
    margin-bottom: 0;
    font-size: 1.1rem;
    font-weight: bold;
    color: ${colors.textDark};
  }
`;

export const TaxInfo = styled.p`
  font-size: 0.7rem;
  color: ${colors.textDark};
  margin-bottom: 0;
`;

export const FooterContent = styled.div`
  display: flex;
  height: 60px;
  flex-direction: row;
  padding: 5px;
  flex-direction: row;
  align-self: stretch;
  justify-content: space-between;
  background-color: ${colors.background};
`;

export const FooterButtonLeft = styled(Link)`
  display: flex;
  flex: 1;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  background-color: ${colors.white};
  border: 0;
  transition: background 0.5s;
  :hover {
    background-color: ${shade(0.1, colors.white)};
    text-decoration: none;
  }
`;

export const FooterButtonRight = styled.button`
  display: flex;
  flex: 1;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  background-color: ${colors.background};
  border: 0;
  transition: background 0.5s;
  :hover {
    background-color: ${shade(0.25, colors.background)};
  }
`;

export const FooterButtonLeftTitle = styled.p`
  font-size: 1.1rem;
  font-weight: bold;
  color: ${colors.background};
  margin: 0;
`;

export const FooterButtonRightTitle = styled.p`
  font-size: 1.1rem;
  font-weight: bold;
  color: ${colors.white};
  margin: 0;
`;
