import api from 'services/api';

import {Creators} from './index';

export const produtoRequest =
  ({refresh, pdv, serie, lng}) =>
  async dispatch => {
    dispatch(Creators.produtoRequest(refresh));

    try {
      const response = await api.get(
        `/produtos/?pdv=${pdv.codigo}&webapp=${true}&serie=${serie}&lng=${lng}`,
      );

      if (response.data && response.data.produtos) {
        const {produtos} = response.data;

        if (produtos) {
          const res = {
            cardapio: produtos.cardapio || [],
            favoritos: produtos.favoritos || [],
            alfabetica: produtos.alfabetica || [],
          };

          dispatch(Creators.produtoSuccess(res));
        } else {
          dispatch(Creators.produtoError(true));
        }
      } else {
        dispatch(Creators.produtoError(true));
      }
    } catch (e) {
      dispatch(Creators.produtoError(true));
    }
  };
