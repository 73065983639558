import {encode as btoa} from 'base-64';

export const generateToken = () =>
  btoa(
    JSON.stringify({
      host: {
        api: 'https://2501-189-39-32-185.ngrok-free.app',
      },
      pdv: {
        codigo: 1,
        nome: 'ADM27 / LA BRASA',
        estacao: '4',
      },
      estabelecimento: '1',
      expired: 1,
      serie: '580801',
      mesa: {
        tipo: 'M',
        codigo: 0,
      },
      cartao: {
        tipo: 'C',
        codigo: 10,
      },
      conta: {
        tipo: 1,
        conta: 233,
        nome: 'Cliente',
      },
    }),
  );
