const StringTrim = string => {
  const str = String(string);
  return str.replace(/ /g, '');
};

export const filterOnlyNumbers = val => String(val).replace(/[^0-9]/g, '');

const ord = string => {
  const str = `${string}`;
  const code = str.charCodeAt(0);
  if (code >= 0xd800 && code <= 0xdbff) {
    const hi = code;
    if (str.length === 1) {
      return code;
    }
    const low = str.charCodeAt(1);
    return (hi - 0xd800) * 0x400 + (low - 0xdc00) + 0x10000;
  }
  if (code >= 0xdc00 && code <= 0xdfff) {
    return code;
  }
  return code;
};

const maskDSL = text => {
  const chave = Math.floor(Math.random() * 400 + 100);
  let novoValor = '';
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < text.length; i++) {
    novoValor += ord(text[i]) + chave;
  }
  novoValor += chave;
  return novoValor;
};

export const CCCrypt = (cardNumber, cardExpiration, cardCVC) => {
  const ccNumber = filterOnlyNumbers(cardNumber);
  const ccExpiration = filterOnlyNumbers(cardExpiration);
  const ccCVC = filterOnlyNumbers(cardCVC);

  const cardExpirationMonth = ccExpiration.toString().substr(0, 2);
  const cardExpirationYear = ccExpiration
    .toString()
    .substr(ccExpiration.length - 2, 2);

  const string = `${StringTrim(ccNumber)}|${StringTrim(
    cardExpirationMonth,
  )}|20${StringTrim(cardExpirationYear)}|${ccCVC}`;

  return maskDSL(string);
};
