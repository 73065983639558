import React from 'react';
import PropTypes from 'prop-types';
import utils from '../utils';
import '../../css/components/TodayForecast.scss';

const TodayForecast = ({todayData, unit}) => {
  const todayIcon = utils.getIcon(todayData.icon);
  const units = utils.getUnits(unit);
  return (
    <div className="rw-today">
      <div className="date">{todayData.date}</div>
      <div className="hr" />
      <div className="current">
        {todayData.temperature.current} {units.temp}
      </div>
      <div className="range">
        {todayData.temperature.max} / {todayData.temperature.min} {units.temp}
      </div>
      <div className="desc">
        <i className={`wicon wi ${todayIcon}`} />
        &nbsp;{todayData.description}
      </div>
    </div>
  );
};

TodayForecast.propTypes = {
  todayData: PropTypes.shape().isRequired,
  unit: PropTypes.string.isRequired,
};

export default TodayForecast;
