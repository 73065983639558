import styled from 'styled-components';
import {shade, lighten} from 'polished';

import {colors} from 'styles';
import {Input} from 'reactstrap';

export const Container = styled.div`
  padding: 0;
`;

export const ProductTitle = styled.h2`
  display: inline-block;
  font-size: 0.9rem;
  font-weight: bold;
  color: ${colors.textDark};
  margin-bottom: 2px;
  word-wrap: break-word;
  text-overflow: ellipsis;
  max-height: 2.2em;
  overflow: hidden;
  text-align: left;
`;

export const ProductPrice = styled.p`
  font-size: 0.8rem;
  font-weight: bold;
  font-weight: normal;
  color: ${colors.textDark};
  margin-bottom: 5px;
  text-align: left;
`;

export const ProductDescription = styled.p`
  font-size: 0.8rem;
  color: ${colors.textDark};
  margin-bottom: 0;
  font-style: italic;
`;

export const ProductScroll = styled.div`
  display: flex;
  flex-direction: column;
  max-height: ${props => `${props.$maxHeight}px` || '390px'};
  overflow-y: auto;
  overflow-x: auto;
`;

export const ProductContent = styled.div`
  padding: 5px 10px 0px 10px;
`;

export const ProductAddContainer = styled.div`
  display: flex;
  height: 80px;
  flex-direction: row;
  padding: 10px 10px;
`;

export const ProductAddContainerLeft = styled.div`
  display: flex;
  flex: 1;
  background-color: ${colors.background};
  padding: 2px;
  flex-grow: 1;
`;

export const ProductAddContainerLeftContent = styled.div`
  display: flex;
  flex: 1;
  background: ${colors.white};
  justify-content: space-between;
  align-items: center;
`;

export const ProductAddContainerRight = styled.button`
  display: flex;
  flex: 1;
  flex-grow: 2;
  justify-content: center;
  align-items: center;
  background-color: ${colors.background};
  border: 0;
  transition: background 0.5s;
  :hover {
    background-color: ${shade(0.25, colors.background)};
  }
`;

export const ProductRightAddTitle = styled.p`
  font-size: 1.1rem;
  font-weight: bold;
  color: ${colors.white};
  margin: 0 5px 0 0;
`;

export const ProductRightAddPrice = styled.p`
  font-size: 1rem;
  font-style: italic;
  color: ${colors.white};
  margin: 0;
`;

export const ProductAmount = styled.p`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  color: ${colors.background};
  margin: 0;
`;

export const TaxInfo = styled.p`
  font-size: 0.7rem;
  color: ${colors.textDark};
  margin-bottom: 0;
`;

export const ProductObsContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0;
  margin: 10px 0;
`;

export const ProductObsInput = styled(Input)`
  background-color: ${colors.white};
  border-color: ${colors.grayDark};
  border-width: 2px;
  border-radius: 0.25rem;
  transition: none;
  height: 80px;
  outline: 0;
  margin: 2px;
  transition: background 0.5s;
  font-size: 0.9rem;
  color: ${colors.textDark};
  resize: none;
  ::placeholder {
    color: ${colors.grayDark};
    opacity: 0.5;
  }
  :focus {
    outline: 0;
    border-color: ${colors.grayDark};
    box-shadow: none;
  }
  :disabled {
    background-color: ${colors.gray};
  }
`;

export const ProductIncreaseDecresaButton = styled.button`
  justify-content: center;
  align-items: center;
  background-color: ${colors.white};
  border: 0;
  width: 30px;
`;

export const ImagemContainer = styled.div`
  position: relative;
  max-height: 150px;
  min-height: 100px;
  display: flex;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  padding: 0px;
`;

export const Imagem = styled.img`
  object-fit: cover;
  max-width: 100%;
  height: auto;
  max-height: 100%;
  border-radius: 10px;
`;

export const ComboImg = styled.img`
  width: 25px;
  height: 25px;
  position: absolute;
  top: 5px;
  left: 5px;
`;

export const ComboContainer = styled.div`
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: ${colors.grayLight};
`;

export const TagContainer = styled.div`
  align-items: flex-start;
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 5px;
`;

export const Tag = styled.button`
  align-items: center;
  justify-content: center;
  min-width: 120px;
  min-height: 30px;
  border-width: 2px;
  border-style: solid;
  border-color: ${props => (props.$active ? colors.blue : colors.grayDark)};
  background-color: ${props =>
    props.$active ? lighten(0.1, colors.blue) : colors.white};
  border-radius: 10px;
  margin-bottom: 5px;
  margin-right: 5px;
  :hover {
    border-color: ${colors.blue};
  }
`;

export const TagTitle = styled.p`
  font-size: 0.8rem;
  color: ${props => (props.$active ? colors.white : colors.textDark)};
  margin: 0;
`;

export const AdditionalContainer = styled.div`
  max-height: 250px;
  overflow-y: auto;
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: ${colors.grayLight};
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: ${colors.grayLight};
`;

export const AdditionalTitle = styled.p`
  margin-top: 5px;
  margin-bottom: 0px;
  font-size: 0.9rem;
  color: ${colors.blue};
`;
