import {useEffect, useMemo} from 'react';
import {useLocation} from 'react-router-dom';
import {useSelector} from 'react-redux';

import {sendAnalyticsEvent} from 'helpers/analytics';

export const Tracker = () => {
  const {config, configured} = useSelector(reduxState => reduxState.config);
  const location = useLocation();

  const companyId = useMemo(() => config.dadoshotel?.dados?.id, [config]);

  useEffect(() => {
    if (configured) {
      if (process.env.NODE_ENV !== 'development' && companyId) {
        sendAnalyticsEvent('G-V0XL7GXEWR', 'pageview', {
          page_path: `/${companyId}${location.pathname}`,
          page_title: document.title,
        });
      }
    }
  }, [configured, companyId, location.pathname]);

  return null;
};
