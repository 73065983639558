import React from 'react';

import {useTranslation} from 'react-i18next';

import Main from 'routes/partials/main';
import HeaderBack from 'routes/partials/headerBack';

import {HeaderInfo} from 'components/headerInfo';

import {Container} from './styles';

const Chat = () => {
  const {t} = useTranslation();

  return (
    <>
      <HeaderBack title={t('layout.chat')} />
      <Main hasHeader>
        <Container>
          <HeaderInfo />
        </Container>
      </Main>
    </>
  );
};

export default Chat;
