import React, {useCallback, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import OpenWeatherApi from '../OpenWeatherApi';
import utils from '../utils';
import TodayForecast from './TodayForecast';
import DaysForecast from './DaysForecast';
import WeatherIcon from './WeatherIcon';
import '../../css/components/ReactWeather.scss';

const ReactWeather = ({
  lang = 'en',
  type = 'city',
  lon = null,
  lat = null,
  city = null,
  unit = 'metric',
  apikey = null,
  forecast = 'today',
}) => {
  const [data, setData] = useState(null);

  const getParams = useCallback(() => {
    switch (type) {
      case 'city':
        return {q: city, lang};
      case 'geo':
        return {
          lat,
          lon,
          lang,
        };
      default:
        return {
          q: 'auto:ip',
          lang,
        };
    }
  }, [city, lang, lat, lon, type]);

  const getForecastData = useCallback(() => {
    const api = new OpenWeatherApi(unit, apikey, lang);
    const params = getParams();
    let promise = null;
    promise = api.getForecast(params);
    promise.then(result => {
      setData(result);
    });
  }, [unit, lang, apikey, getParams]);

  useEffect(() => {
    getForecastData();
  }, [getForecastData]);

  if (data) {
    const {days} = data;
    const today = data.current;
    const todayIcon = utils.getIcon(today.icon);
    return (
      <div className="rw-box">
        <div className={`rw-main type-${forecast}`}>
          <div className="rw-box-left">
            {/* <h2>{data.location.name}</h2> */}
            <TodayForecast todayData={today} unit={unit} />
          </div>
          <div className="rw-box-right">
            <WeatherIcon name={todayIcon} />
          </div>
        </div>
        <DaysForecast
          unit={unit}
          forecast={forecast}
          daysData={days}
          lang={lang}
        />
      </div>
    );
  }
  return null;
};

ReactWeather.propTypes = {
  unit: PropTypes.oneOf(['metric', 'imperial']),
  type: PropTypes.oneOf(['geo', 'city']),
  lat: PropTypes.string,
  lon: PropTypes.string,
  city: PropTypes.string,
  forecast: PropTypes.oneOf(['today', '5days']),
  apikey: PropTypes.string.isRequired,
  lang: PropTypes.string,
};

export default ReactWeather;
