import styled from 'styled-components';

import {colors} from 'styles';

export const ItemHeader = styled.a`
  display: flex;
  flex-shrink: 0;
  background-color: ${colors.grayLight};
  flex-direction: row;
  align-items: center;
  margin-top: 0.5px;
`;

export const Card = styled.a`
  display: flex;
  flex-shrink: 0;
  background-color: ${colors.white};
  flex-direction: column;
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 5px;
  min-height: 100px;
`;

export const CardContent = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ItemNameContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: auto;
  align-items: center;
  align-self: stretch;
  background-color: ${colors.transparent};
  justify-content: space-between;
  align-items: flex-start;
  padding: 2px;
  overflow: hidden;
`;

export const ItemStatusContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 25%;
  align-items: center;
  background-color: ${colors.transparent};
  justify-content: flex-end;
  align-items: center;
  padding: 2px;
`;

export const ItemTextHeader = styled.p`
  color: ${colors.textDark};
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 0;
`;

export const ItemText = styled.p`
  color: ${colors.textDark};
  font-size: 0.9rem;
  margin-bottom: 5px;
  margin-top: 5px;
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: bold;
`;

export const ItemSubText = styled.p`
  color: ${colors.textDark};
  font-size: 0.9rem;
  margin-bottom: 0;
  margin-top: 5px;
  margin-right: 5px;
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const ItemSubGroup = styled.div``;
